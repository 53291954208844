import React from 'react';
import Image from '@bit/kards.kards-components.image';
import { useTheme } from 'src/modules/card/context/theme';

function ImageComponent({ data }) {
  const theme = useTheme();

  return (
    <Image.component
      theme={theme}
      title={data?.title}
      description={data?.description}
      picture={data?.picture}
      link={data?.link}
      full={data?.full}
    />
  );
}
export default React.memo(ImageComponent, () => true);
