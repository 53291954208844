import firebase from 'firebase/app';

export function attachIntents({ payment_method = null }) {
  const attachIntents = firebase
    .app()
    .functions()
    .httpsCallable('attachIntents');

  return attachIntents({ payment_method }).then(res => res.data);
}

export function createAndConfirmPaymentIntents({ order, payment_method = null, preorder, saveCard }) {
  const createAndConfirmPaymentIntents = firebase
    .app()
    .functions()
    .httpsCallable('createAndConfirmPaymentIntents');

  return createAndConfirmPaymentIntents({ order, payment_method, preorder, saveCard });
}

export function createPaymentIntentsFromExisting({ amount, orderId }) {
  const retrievePaymentIntentAndPay = firebase
    .app()
    .functions()
    .httpsCallable('retrievePaymentIntentAndPay');

  return retrievePaymentIntentAndPay({ amount, orderId }).then(res => res.data);
}

export function createStripeCustomer({ user }) {
  const createCustomerStripe = firebase
    .app()
    .functions()
    .httpsCallable('createCustomerStripe');

  return createCustomerStripe({ user }).then(res => res.data);
}

export function detachPaymentMethod({ payment_method = null }) {
  const detachPaymentMethods = firebase
    .app()
    .functions()
    .httpsCallable('detachPaymentMethods');

  return detachPaymentMethods({ payment_method }).then(res => res.data);
}

export function listUserCreditCards() {
  const listCustomersCard = firebase
    .app()
    .functions()
    .httpsCallable('listCustomersCard');

  return listCustomersCard().then(res => res.data);
}
