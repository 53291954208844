/* eslint-disable import/no-extraneous-dependencies */
import React, { useContext } from 'react';
import styled from 'styled-components';
import { get } from 'lodash';
import {
  IonContent,
  IonList,
  IonItem,
} from '@ionic/react';
import { colors } from '@bit/kards.kards-components.helpers';
import { useHistory } from 'react-router';

import { ThemeContext } from '../../card/context/theme';


const IonItemWrapper = styled(IonItem)`
  --inner-border-width: 0;
  --border-width: 0;
  --inner-padding-top: 0;
  --inner-padding-bottom: 0;
  width: 95%;
`;

const RowWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  border-bottom: ${(props) => props.borderBottom};
  border-top: ${(props) => props.borderTop};
  --inner-border-width: 0;
  padding-bottom: .8em;
  padding-top: .8em;
`;

const Help = () => {
  const {
    theme,
  }: any = useContext(ThemeContext);
  const history = useHistory();

  return (
    <IonContent color={get(theme, 'color.background')}>
      <IonList style={{ padding: '1em 0', background: colors[get(theme, 'color.background')] }}>
        <IonItemWrapper onClick={() => history.push('/menu/help/1')} lines="inset" color={get(theme, 'color.background')}>
          <RowWrapper color={colors[get(theme, 'color.title')]}>
            Je souhaite contacter le support de Kards.
          </RowWrapper>
        </IonItemWrapper>
        <IonItemWrapper onClick={() => history.push('/menu/help/2')} color={get(theme, 'color.background')}>
          <RowWrapper color={colors[get(theme, 'color.title')]} borderTop={`solid 1px ${colors[get(theme, 'color.title')]}`}>
            Je ne retrouve pas la commande que je viens de passer.
          </RowWrapper>
        </IonItemWrapper>
        <IonItemWrapper onClick={() => history.push('/menu/help/3')} color={get(theme, 'color.background')}>
          <RowWrapper color={colors[get(theme, 'color.title')]} borderTop={`solid 1px ${colors[get(theme, 'color.title')]}`}>
            Ma commande met plus de temps que l’estimation à être préparée.
          </RowWrapper>
        </IonItemWrapper>
        <IonItemWrapper onClick={() => history.push('/menu/help/4')} color={get(theme, 'color.background')}>
          <RowWrapper color={colors[get(theme, 'color.title')]} borderTop={`solid 1px ${colors[get(theme, 'color.title')]}`}>
            Je ne comprend pas pourquoi je dois rentrer mon téléphone pour payer.
          </RowWrapper>
        </IonItemWrapper>
      </IonList>
    </IonContent>
  );
};

export default Help;
