import React, { createContext, useContext } from 'react';

export const ThemeContext = createContext({});

export function useTheme() {
  const { theme }:any = useContext(ThemeContext);
  return theme;
}

function ThemeProvider({ children, theme }) {
  const value = { theme };
  return (
    <ThemeContext.Provider value={value}>{children}</ThemeContext.Provider>
  );
}

export default ThemeProvider;
