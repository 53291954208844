import React from 'react';
import { useTheme } from 'src/modules/card/context/theme';
import { Row, TextNotAvailable } from './utils';

export function Unavailable() {
  const theme = useTheme();
  return (
    <Row>
      <TextNotAvailable data-cy="unavailable-product" color={theme?.color?.productTitle}>
        indisponible
      </TextNotAvailable>
    </Row>
  );
}
