import React from 'react';
import { Switch, Route } from 'react-router-dom';
import Paid from './components/Paid';
import Confirm from './components/Confirm';

export default function Order() {
  return (
    <Switch>
      <Route path="/orders/confirm" exact>
        <Confirm />
      </Route>
      <Route path="/orders/card/:step">
        <Paid />
      </Route>
    </Switch>
  );
}
