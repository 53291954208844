import React from 'react';
import { getComponents } from './getComponents';

function Component({ data }) {
  const Component: any = getComponents(data.type);
  return <Component data={data.value} />;
}

const Item = ({ item, index }) => (
  <div style={{ width: '100%' }} data-cy={`btn-component-${index}`}>
    <Component data={item} />
  </div>
);

export default Item;
