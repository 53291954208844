import React from 'react';
import { get } from 'lodash';
import { IonButton, IonHeader, IonToolbar, IonText, IonRow, IonIcon } from '@ionic/react';
import { close as closeIcon, arrowBack } from 'ionicons/icons';
import styled from 'styled-components';
import { colors } from '@bit/kards.kards-components.helpers';
import { useHistory } from 'react-router-dom';
import { useTheme } from 'src/modules/card/context/theme';

const ToolbarWrapper = styled(IonToolbar)`
  background-color: ${props => props.color};
  display: flex;
  justify-content: center;
  align-items: center;
`;

const IonIconWrapper = styled(IonIcon)`
  font-size: ${props => props.fontSize};
  padding: 0;
`;

export default function Header({ title }) {
  const theme = useTheme();
  const history = useHistory();

  return (
    <IonHeader>
      <ToolbarWrapper color={colors[get(theme, 'color.backgroundHeader')]}>
        <IonRow className="ion-justify-content-center ion-align-items-center">
          <div
            style={{
              flex: 0.3,
              display: 'flex',
              justifyContent: 'flex-start',
              alignItems: 'center',
            }}
          >
            <IonButton
              fill="clear"
              onClick={() => {
                history.goBack();
              }}
            >
              <IonIconWrapper fontSize="2em" color={get(theme, 'color.buttonHeaderColor')} icon={arrowBack} />
            </IonButton>
          </div>
          <div
            style={{
              flex: 0.4,
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <IonText color={get(theme, 'color.buttonHeaderColor')} style={{ fontSize: '1.2em', fontWeight: 'bold', whiteSpace: 'nowrap' }}>
              {title}
            </IonText>
          </div>
          <div
            style={{
              flex: 0.3,
              display: 'flex',
              justifyContent: 'flex-end',
              alignItems: 'center',
              padding: 0,
            }}
          >
            <IonButton
              fill="clear"
              onClick={() => {
                history.push('/');
              }}
            >
              <IonIconWrapper fontSize="2.5em" color={get(theme, 'color.buttonHeaderColor')} icon={closeIcon} />
            </IonButton>
          </div>
        </IonRow>
      </ToolbarWrapper>
    </IonHeader>
  );
}
