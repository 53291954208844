import React from 'react';
import Product from '@bit/kards.kards-components.product';
import get from 'lodash/get';
import { Link } from 'react-router-dom';
import { AddRemoveBasket } from 'src/modules/basket/components/AddRemoveBasket';
import { useTheme } from 'src/modules/card/context/theme';

const LinkWithCheck = ({ stock, settingFields, to, description, ...props }) =>
  (settingFields && (get(stock, 'unlimited') || parseInt(get(stock, 'stockNumber'), 10) > 0)) ||
  (description && description.length > 60) ? (
    <Link to={to} {...props} />
  ) : (
    <span {...props} />
  );

export default ({ data }) => {
  const theme = useTheme();

  return (
    <LinkWithCheck
      key={get(data, 'id')}
      style={{
        textDecoration: 'none',
      }}
      description={get(data, 'description')}
      stock={get(data, 'stock')}
      settingFields={get(data, 'settingFields') && get(data, 'settingFields').length > 0}
      to={{
        pathname: '/product',
        state: { product: data },
      }}
    >
      <Product.component
        theme={theme}
        name={get(data, 'reference')}
        stock={get(data, 'stock')}
        picture={get(data, 'picture')}
        id={get(data, 'id')}
        description={get(data, 'description')}
        settingFields={get(data, 'settingFields')}
        price={get(data, 'price')}
        tva={get(data, 'tva')}
        key={get(data, 'id')}
        width="100%"
        read
      >
        <AddRemoveBasket />
      </Product.component>
    </LinkWithCheck>
  );
};
