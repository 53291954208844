/* eslint-disable import/no-extraneous-dependencies */
import React, { useContext, useState } from 'react';
import styled from 'styled-components';
import { get } from 'lodash';
import {
  IonContent,
  IonList,
  IonItem,
  IonLabel,
  IonButton,
  IonSpinner,
} from '@ionic/react';
import { colors } from '@bit/kards.kards-components.helpers';
import { useHistory } from 'react-router';
import firebase from 'firebase';
import { ReadContext } from 'src/modules/card/context';
import { ThemeContext } from 'src/modules/card/context/theme';

import { ReactComponent as About } from 'src/svg/about.svg';
import { ReactComponent as Help } from 'src/svg/help.svg';
import { ReactComponent as Order } from 'src/svg/order.svg';
import { ReactComponent as Contact } from 'src/svg/contact.svg';
import { ReactComponent as Arrow } from 'src/svg/arrow.svg';


const SignOutButton = () => {
  const {
    user,
  }: any = useContext(ReadContext);
  const {
    theme,
  }: any = useContext(ThemeContext);
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);

  const signOut = () => {
    if (loading) return;
    setLoading(true);
    setTimeout(() => {
      firebase.auth().signOut().then(() => {
        // Sign-out successful.
        setLoading(false);
      }).catch((e) => {
        setError(e);
        console.log('Log: signOut -> error', error);
        // An error happened.
      });
    }, 2000);
  };

  return (
    <>
      {user ? (
        <IonButton
          color={get(theme, 'color.title')}
          size="small"
          style={{
            margin: '4vh',
            fontSize: '0.8em',
            '--border-radius': '30px',
          }}
          onClick={signOut}
        >
          {loading && <IonSpinner style={{ color: colors[get(theme, 'color.title')] }} name="lines-small" />}
          Deconnecter mon numéro
        </IonButton>
      )
        : (
          <IonButton
            color={get(theme, 'color.title')}
            size="small"
            style={{
              margin: '4vh',
              fontSize: '0.8em',
              '--border-radius': '30px',
            }}
            onClick={() => history.push('/auth')}
          >
            Connecter mon numéro
          </IonButton>
        )}
    </>
  );
};
const IonItemWrapper = styled(IonItem)`
  --inner-border-width: 0;
  --border-width: 0;
  --inner-padding-top: 0;
  --inner-padding-bottom: 0;
`;

const RowWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  border-bottom: ${(props) => props.borderBottom};
  margin-top: ${(props) => props.marginTop};
  border-top: ${(props) => props.borderTop};
  --inner-border-width: 0;
  padding-bottom: .7em;
  padding-top: .7em;
`;

const Box = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  flex: ${(props) => props.flex};
`;

const ArrowSvg = styled(Arrow)`
  width: .6em;
  margin: 0 1em;
  pa
  .st0{
    fill: ${(props) => props.color} !important;
  }
`;

const OrderSvg = styled(Order)`
  width: 1.5em;
  margin: 0 1em;
  .st0{
    fill: ${(props) => props.color} !important;
  }
`;

const HelpSvg = styled(Help)`
  width: 1.5em;
  margin: 0 1em;
  .st0{
    fill: ${(props) => props.color} !important;
  }
`;

const ContactSvg = styled(Contact)`
  width: 1.5em;
  margin: 0 1em;
  .st0{
    fill: ${(props) => props.color} !important;
  }
`;

const AboutSvg = styled(About)`
  width: 1.5em;
  margin: 0 1em;
  .st0{
    fill: ${(props) => props.color} !important;
  }
`;

const Menu = () => {
  const {
    theme,
  }: any = useContext(ThemeContext);

  const history = useHistory();

  return (
    <IonContent color={get(theme, 'color.background')}>
      <IonList style={{ padding: '1em 0', background: colors[get(theme, 'color.background')] }}>
        <IonItemWrapper onClick={() => history.push('/menu/history')} lines="inset" color={get(theme, 'color.background')}>
          <RowWrapper data-cy="order-list" color={colors[get(theme, 'color.title')]} borderTop={`solid 1px ${colors[get(theme, 'color.title')]}`}>
            <Box flex=".3">
              <OrderSvg color={colors[get(theme, 'color.title')]} />
              <IonLabel>Mes commandes</IonLabel>
            </Box>
            <ArrowSvg color={colors[get(theme, 'color.title')]} />
          </RowWrapper>
        </IonItemWrapper>
        <IonItemWrapper onClick={() => history.push('/menu/help')} color={get(theme, 'color.background')}>
          <RowWrapper color={colors[get(theme, 'color.title')]} borderTop={`solid 1px ${colors[get(theme, 'color.title')]}`}>
            <Box flex=".3">
              <HelpSvg color={colors[get(theme, 'color.title')]} />
              <IonLabel>{'Besoin d\'aide ?'}</IonLabel>
            </Box>
            <ArrowSvg color={colors[get(theme, 'color.title')]} />
          </RowWrapper>
        </IonItemWrapper>
        <IonItemWrapper onClick={() => history.push('/menu/contacts')} color={get(theme, 'color.background')}>
          <RowWrapper color={colors[get(theme, 'color.title')]} borderTop={`solid 1px ${colors[get(theme, 'color.title')]}`} rTop>
            <Box flex=".3">
              <ContactSvg color={colors[get(theme, 'color.title')]} />
              <IonLabel>Nous contacter</IonLabel>
            </Box>
            <ArrowSvg color={colors[get(theme, 'color.title')]} />
          </RowWrapper>
        </IonItemWrapper>
        <IonItemWrapper onClick={() => history.push('/menu/about')} color={get(theme, 'color.background')}>
          <RowWrapper color={colors[get(theme, 'color.title')]} borderTop={`solid 1px ${colors[get(theme, 'color.title')]}`} borderBottom={`solid 1px ${colors[get(theme, 'color.title')]}`}>
            <Box flex=".3">
              <AboutSvg color={colors[get(theme, 'color.title')]} />
              <IonLabel>À propos</IonLabel>
            </Box>
            <ArrowSvg color={colors[get(theme, 'color.title')]} />
          </RowWrapper>
        </IonItemWrapper>
        <SignOutButton />
      </IonList>
    </IonContent>
  );
};

export default Menu;
