import React from 'react';
import { useTheme } from 'src/modules/card/context/theme';
import { IonContent } from '@ionic/react';

type ContentProps = Omit<React.ComponentProps<typeof IonContent>, 'color'>;

export function Content({ ...props }: ContentProps) {
  const theme = useTheme();
  return <IonContent color={theme?.color?.background} {...props} />;
}
