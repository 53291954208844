import React from 'react';
import { ReadProvider } from 'src/modules/card/context';
import { BasketProvider } from 'src/modules/basket';
import ThemeProvider from 'src/modules/card/context/theme';
import Header from 'src/common-ui/header';
import { Routes } from './Routes';

export function Card({ data, averageTime = 0, start = true, open }) {
  const isLoading = !data || Object.keys(data).length === 0;
  if (isLoading)
    return (
      <img
        data-cy="screenOff"
        alt="screenOff"
        src="/img/kards/logo.svg"
        style={{ background: 'white', height: '100%', width: '100%', padding: '20px' }}
      />
    );

  return (
    <ReadProvider open={open} averageTime={averageTime} apiData={data} start={start}>
      <BasketProvider>
        <ThemeProvider theme={data?.theme}>
          <Header orderMode={data?.order_mode} template={data?.template.name} />
          {Object.keys(data).length > 0 && <Routes />}
        </ThemeProvider>
      </BasketProvider>
    </ReadProvider>
  );
}
