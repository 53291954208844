import { connect, getIn } from 'formik';
import { get } from 'lodash';
import React, { useEffect } from 'react';
import styled from 'styled-components';
import { IonLabel, IonInput, IonRow } from '@ionic/react';
import { useTheme } from 'src/modules/card/context/theme';

const IonLabelWrapper = styled(IonLabel)`
  align-items: center;
  display: flex !important;
  flex-direction: row;
  padding-right: 0.5em;
`;

export const OnPlace = ({ setFieldValue, value }) => {
  const theme = useTheme();

  return (
    <IonRow style={{ paddingLeft: '2em' }}>
      <IonLabelWrapper color={get(theme, 'color.title')}>N°:</IonLabelWrapper>
      <IonInput
        data-cy="onplace_number"
        onIonChange={e => setFieldValue('onplace_number', e.detail.value)}
        value={value}
        type="number"
        placeholder=" “ex: 22“ "
      />
    </IonRow>
  );
};

const OptionalField = ({ formik, name, condition }) => {
  const { values, setFieldValue } = formik;

  useEffect(() => {
    // set value if not defined
    if (getIn(values, condition) === 'delivered') {
      setFieldValue('onplace_number', null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values]);

  const value = getIn(values, condition);
  const valuess = getIn(values, name);
  // stop rendering, effect above will rerender
  if (value === 'delivered') return null;

  return <OnPlace value={valuess} setFieldValue={setFieldValue} />;
};

export default connect(OptionalField);
