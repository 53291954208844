import React from 'react';
import { NavLink, NavLinkProps } from 'react-router-dom';
import styled from 'styled-components';
import { colors } from '@bit/kards.kards-components.helpers';
import { useTheme } from 'src/modules/card/context/theme';

const Link = styled(NavLink)<{ disabled?: boolean; backgroundcolor: string; color: string; }>`
  background-color: ${props => colors[props.backgroundcolor]};
  border-radius: 30px;
  opacity: ${props => (props.disabled ? 0.3 : 1)};
  color: ${props => colors[props.color]};
  text-decoration: none;
  padding: 7px 14px;
  font-weight: bold;
  text-align: center;
`;

export function ButtonLink({ to, disabled, ...props }: { disabled?: boolean } & NavLinkProps) {
  const theme = useTheme();
  return <Link to={disabled ? '#' : to} disabled={disabled} backgroundcolor={theme?.color?.title} color={theme?.color?.background} {...props} />;
}
