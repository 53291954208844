import React, { useContext } from 'react';
import Wheel from '@bit/kards.kards-components.wheel';
import { ThemeContext } from 'src/modules/card/context/theme';

export default ({ data }) => {
  const {
    theme,
  }: any = useContext(ThemeContext);

  return (
    <Wheel.component
      theme={theme}
      data={data}
    />
  );
};
