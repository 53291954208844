import React from 'react';
import { get } from 'lodash';
import { Formik, Field } from 'formik';
import { useTheme } from 'src/modules/card/context/theme';
import { ItemRadioGroupDelivery } from './RadioGroup';
import OptionalField from './onPlaceInput';
import { Text } from '../Text';

export function DeleveryField({ name = 'delivery', deliveryInstruction, deliveryInstructionAdresse, deliveryActive }) {
  const theme = useTheme();

  return (
    <Field name={name}>
      {({ field }) => (
        <>
          <Text
            fontWeigth="bold"
            width="100%"
            justifyContent="flex-start"
            color={get(theme, 'color.title')}
            paddingBottom=".2em"
            paddingTop="2em"
          >
            Récuperer la commande
          </Text>
          <ItemRadioGroupDelivery
            name="delivery"
            items={deliveryActive}
            theme={theme}
            current={field.value}
            onChange={field.onChange}
            deliveryInstruction={deliveryInstruction}
            deliveryInstructionAdresse={deliveryInstructionAdresse}
            allowEmptySelection={false}
          />
          <OptionalField condition="delivery" name="onplace_number" formik={Formik} />
        </>
      )}
    </Field>
  );
}
