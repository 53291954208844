import React from 'react';
import { useLocation } from 'react-router-dom';
import {
  IonSkeletonText, IonRow,
} from '@ionic/react';
import { SelectModeOrdering } from 'src/modules/card/components/SelectModeOrdering';
import MainHeader from './components/main';
import MenuHeader from './components/menu';
import OrderHeader from './components/order';
import AuthHeader from './components/auth';


const mapHeaderLocationToTitle = {
  '/orders/confirm': 'Votre commande',
  '/auth': 'Connexion',
  '/orders/card/paid': 'Payer par carte',
  '/orders/card/new': 'Ajouter une carte',
  '/orders/card/first': 'Payer par carte',
  '/orders/lydia': 'Lydia',
  '/product': '',
  '/menu': 'Menu',
  '/menu/history': 'Mes commandes',
  '/menu/contacts': 'Nous contacter',
  '/menu/help': 'Besoin d\'aide',
  '/menu/help/1': 'Besoin d\'aide',
  '/menu/help/2': 'Besoin d\'aide',
  '/menu/help/3': 'Besoin d\'aide',
  '/menu/help/4': 'Besoin d\'aide',
  '/menu/about': 'À propos',
};

const Header = ({ template, orderMode }) => {
  const location = useLocation();
  if (!template) {
    return (
      <IonRow className="ion-justify-content-center ion-align-items-center">
        <IonSkeletonText animated style={{ height: '10vh', width: '100%%' }} />
      </IonRow>
    );
  }
  if (location.pathname.includes('/orders')) return (<OrderHeader title={mapHeaderLocationToTitle[location.pathname]} />);
  if (location.pathname.includes('/auth')) return (<AuthHeader title={mapHeaderLocationToTitle[location.pathname]} />);
  if (location.pathname.includes('/menu')) return (<MenuHeader title={mapHeaderLocationToTitle[location.pathname]} />);
  if (location.pathname.includes('/')) {
    return (
      <>
        <MainHeader template={template} />
        {orderMode === 'preorder' && <SelectModeOrdering />}
      </>
    );
  }
};


export default Header;
