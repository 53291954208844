import React, { useContext } from 'react';
import Gallery from '@bit/kards.kards-components.categories-gallery';
import { ReadContext } from 'src/modules/card/context';
import { ThemeContext } from 'src/modules/card/context/theme';

const GalleryList = ({ components }) => {
  const { cardData, setCurrentCategory, currentCategory }: any = useContext(ReadContext);
  const { theme }: any = useContext(ThemeContext);

  return (
    <Gallery.component
      theme={theme}
      currentCategory={currentCategory}
      setCurrentCategory={setCurrentCategory}
      data={cardData}
      components={components}
    />
  );
};

export default GalleryList;
