import React, { useContext } from 'react';
import Social from '@bit/kards.kards-components.social';
import { ThemeContext } from 'src/modules/card/context/theme';

export default ({ data: { facebook, twitter, instagram } }) => {
  const {
    theme,
  }: any = useContext(ThemeContext);

  return (
    <Social.component
      theme={theme}
      facebook={facebook}
      twitter={twitter}
      instagram={instagram}
    />
  );
};
