import React from 'react';

export function PrivateNavigation() {
  return (
    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
      <div style={{ textAlign: 'center' }}>
        <h1>Vous êtes en mode navigation privée</h1>
      </div>
      <div style={{ textAlign: 'center' }}>
        <p>Si vous restez en navigation privée il y a de forte chance pour que vous ne receviez jamais votre commande</p>
      </div>
      <div style={{ textAlign: 'center' }}>
        <p>
          Nous préferons donc vous obliger à utiliser la <strong>navigation public</strong>{' '}
        </p>
      </div>
      <div>
        <h3> A desuite ! </h3>
      </div>
      <img style={{ height: '20vh' }} src="/img/utils/detective.svg" alt="detective" />
    </div>
  );
}
