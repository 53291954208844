import React, { useState } from 'react';
import { useReadContext } from 'src/modules/card/context';
import { useTheme } from 'src/modules/card/context/theme';
import styled from 'styled-components';
import { IonButton, IonHeader } from '@ionic/react';
import { colors } from '@bit/kards.kards-components.helpers';
import { ModalPreOrder } from './ModalPreOrder';

export const SelectModeOrdering = () => {
  const { day, service, mode }: any = useReadContext();
  const theme = useTheme();
  const { isOpen, openModal, closeModal } = useModal();

  return (
    <>
      <MainContainer background={theme?.color.backgroundHeader}>
        <MainContent color={theme?.color.title}>
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <p style={{ fontWeight: 'bold', color: 'rgb(200,200,200)' }}>Pour quand voulez vous commander ?</p>
            <ContainerButton>
              <Button data-cy="preorder" color={theme?.color?.title} onClick={openModal}>
                {service && service.label && day && day.label ? `Pour ${day.label} ${service.label}` : 'Commander'}
              </Button>
            </ContainerButton>
          </div>
        </MainContent>
      </MainContainer>
      {mode === 'preorder' && <ModalPreOrder isOpen={isOpen} closeModal={closeModal} />}
    </>
  );
};

function useModal() {
  const [isOpen, setOpenModalPreorder] = useState(false);

  const openModal = () => {
    setOpenModalPreorder(true);
  };

  const closeModal = () => {
    setOpenModalPreorder(false);
  };

  return { isOpen, openModal, closeModal };
}

const MainContainer = styled(IonHeader)`
  margin-bottom: 0px;
  background: ${props => colors[props.background]};
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: -3px;
`;

const MainContent = styled.div`
  background: ${props => colors[props.background]};
  padding: 9px 0;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  font-size: 0.9em;
  flex: 0.9;
`;

const Button = styled(IonButton)`
  --background: ${props => props.bgcolor};
  --border-radius: 20px;
  height: 20px;
  font-size: 0.9em;
  margin: 0;
  margin-right: 1em;
`;

const ContainerButton = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  flex: 0.6;
`;
