import React, { useContext } from 'react';
import { find, map, get } from 'lodash';

import { IonContent, IonList } from '@ionic/react';
import styled from 'styled-components';
// context
import { colors } from '@bit/kards.kards-components.helpers';
import { ReadContext } from '../context';
import { ThemeContext } from '../context/theme';
import Item from './item';

export const ComponentsRead = () => {
  const { cardData, currentCategory }: any = useContext(ReadContext);
  const { theme }: any = useContext(ThemeContext);
  const category = find(cardData, c => c.id === currentCategory);
  const components = get(category, 'components');

  return (
    <>
      <IonContent data-cy="component-list" fullscreen color={theme.color.background}>
        <List color={get(theme, 'color.background')}>
          {map(components, (item, i) => (
            <Item item={item} key={item.id} index={i} />
          ))}
        </List>
      </IonContent>
    </>
  );
};

const List = styled(IonList)`
  background-color: ${props => colors[props.color]};
  padding-bottom: 30vh;
`;
