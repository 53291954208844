import React, { useEffect, useState, Suspense } from 'react';
import { IonApp, IonAlert } from '@ionic/react';
import Media from 'react-media';
import { colors } from '@bit/kards.kards-components.helpers';
import { OrderPreparingProvider, useOrderPreparingContext } from './modules/liveOrder/context';
import { useWatchApplication } from './data/hooks/watchApplicationCard';
import { isPrivateWindow } from './helpers/incognito';
import { Card } from './modules/entrypoint';
import './firebase';
import 'intersection-observer';
/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';
import './App.css';
/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/typography.css';
/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css';
import '@ionic/react/css/float-elements.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/display.css';
/* Theme variables */
import './theme/variables.css';
import { BrowserRouter as Router } from 'react-router-dom';
import { PrivateNavigation, Layout } from './common-ui';

const windowT: any = window;
export const configuration = windowT.kardsConfiguration;

const Ordering = React.lazy(() => import('./modules/liveOrder'));

function CheckIfOrderPreparing({ data }) {
  const { orderPreparing } = useOrderPreparingContext();

  if (orderPreparing.data) return <Ordering orderPreparingFromStorage={orderPreparing.data} />;

  return <Card data={data.compiledData} open={data.open} key={data.key} averageTime={data.averageTime} start={data.start} />;
}

function App() {
  const [isGlobalError, setIsGlobalError] = useState({ error: false, message: null });
  const { data }: any = useWatchApplication({ error: isGlobalError });

  const [isPrivateMode, setIsPrivateMode] = useState(false);
  useEffect(() => {
    isPrivateWindow(setIsPrivateMode);
  }, []);

  return (
    <Router>
      <Media queries={{ small: '(max-width: 599px)', medium: '(min-width: 600px)' }}>
        {matches => (
          <Layout matches={matches}>
            <IonApp style={{ touchAction: 'manipulation', background: colors[data?.compiledData?.theme?.color?.background] }}>
              {isPrivateMode ? (
                <PrivateNavigation />
              ) : (
                <>
                  <IonAlert
                    isOpen={isGlobalError.error}
                    onDidDismiss={() => {
                      setIsGlobalError({ error: null, message: null });
                    }}
                    header="Oups ..."
                    subHeader=""
                    message={isGlobalError.message ? isGlobalError.message : 'Il semble avoir eu un problème sur votre application'}
                    buttons={[
                      {
                        text: 'Recharger mon application',
                        role: 'validate',
                        cssClass: 'secondary',
                        handler: () => window.location.reload(),
                      },
                    ]}
                  />
                  <Suspense fallback={<div />}>
                    <OrderPreparingProvider>
                      <CheckIfOrderPreparing data={data} />
                    </OrderPreparingProvider>
                  </Suspense>
                </>
              )}
            </IonApp>
          </Layout>
        )}
      </Media>
    </Router>
  );
}

export default App;
