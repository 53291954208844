import React from 'react';
import { get } from 'lodash';
import {
  IonFooter, IonToolbar, IonButton, IonText, IonRow, IonSkeletonText, IonSpinner,
} from '@ionic/react';
import styled from 'styled-components';
import { useTheme } from 'src/modules/card/context/theme';
import { colors } from '@bit/kards.kards-components.helpers';

type FooterProps = {
  loading: boolean;
  nbProducts: number;
  title: string;
  price: string;
  modal: boolean;
} & any;

export function Footer({
  loading, nbProducts, title, price, modal, ...props
}: FooterProps) {
  const theme = useTheme();


  if (!theme) {
    return (
      <StyledFooter>
        <IonRow className="ion-justify-content-center ion-align-items-center">
          <IonSkeletonText animated style={{ height: '10vh', width: '100%%' }} />
        </IonRow>
      </StyledFooter>
    );
  }

  return (
    <StyledFooter>
      <Toolbar data-cy={`btn-order${modal ? '-modal' : ''}`} color={theme?.color?.backgroundFooter}>
        <IonRow className="ion-justify-content-center ion-align-items-center">
          <Button {...props} expand="full" color={theme?.color?.backgroundFooter}>
            <div style={{ display: 'none' }} id="submit-button" />
            <div
              style={{
                flex: 0.3,
                display: 'flex',
                justifyContent: 'flex-start',
                alignItems: 'center',
              }}
            >
              {nbProducts >= 0 && (
              <BubbleNumber>
                {loading ? <IonSpinner style={{ color: colors[theme?.color?.title] }} name="lines-small" />
                  : <IonText color={get(theme, 'color.buttonOrder')}>{nbProducts}</IonText>}
              </BubbleNumber>
              )}
            </div>
            <div
              style={{
                flex: 0.4,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <IonText style={{ fontSize: '1em' }} color={theme?.color?.buttonOrder}>
                {loading ? 'Chargement ...' : title}
              </IonText>
            </div>
            <div
              style={{
                flex: 0.3, display: 'flex', justifyContent: 'flex-end', alignItems: 'center',
              }}
            >
              <IonText
                data-cy="btn-order-price"
                style={{ fontSize: '1.4em', textAlign: 'right' }}
                color={theme?.color?.buttonOrder}
              >
                {price}
              </IonText>
            </div>
          </Button>
        </IonRow>
      </Toolbar>
    </StyledFooter>
  );
}

const Button = styled(IonButton)`
  width: 98%;
  --box-shadow: none !important;
`;

const Toolbar = styled(IonToolbar)`
  background-color: ${(props) => props.color};
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;

const BubbleNumber = styled.div`
  display: flex;
  width: 2em;
  height: 2em;
  border-radius: 50%;
  background-color: rgba(100, 100, 100, 0.3);
  justify-content: center;
  align-items: center;
`;

const StyledFooter = styled(IonFooter)`
  position: fixed;
  box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.15);
  bottom: 0px;
  & ::before {
    display: none;
  }
`;
