import React from 'react';
import styled from 'styled-components';
import { colors } from '@bit/kards.kards-components.helpers';
import { useTheme } from 'src/modules/card/context/theme';

const TextRaw = styled.div`
text-align: ${(props) => props.textAlign};
width: ${(props) => props.width};
color: ${(props) => colors[props.color]};
background: ${(props) => colors[props.bgColor]};
font-size: ${(props) => props.fontSize};
font-weight: ${(props) => props.fontWeigth};
font-family: ${(props) => props.family};
padding: ${(props) => props.padding};
margin: ${(props) => props.margin};
margin-left: ${(props) => props.marginmarginLeft};
padding-bottom: ${(props) => props.paddingBottom};
margin-bottom: ${(props) => props.marginBottom};
padding-top: ${(props) => props.paddingTop};
white-space: ${(props) => props.whiteSpace};
line-height: ${(props) => props.lineHeight};
border-radius: ${(props) => props.borderRadius};
justify-content: ${(props) => props.justifyContent};
display: flex;
align-items: center;
`;

export const Text = (props) => {
  const theme = useTheme();
  return <TextRaw color={props.revertColor ? theme?.color?.background :theme?.color?.title} {...props} />;
};
