import React from 'react';
import { useGetOrderByIdOnSnapshot } from 'src/data/accessor/api';
import { useParams, useHistory } from 'react-router';
import { Content, Footer } from 'src/common-ui';
import { alertCircleOutline } from 'ionicons/icons';
import { IonIcon } from '@ionic/react';

export function GuardOrder({ children }) {
  const history = useHistory();
  const { id }: { id: string } = useParams();
  const { loading, order } = useGetOrderByIdOnSnapshot(id);

  if (!loading && !order)
    return (
      <Content>
        <div
          style={{
            textAlign: 'center',
            color: '#ff3030',
            display: 'flex',
            alignItems: 'center',
            flexDirection: 'column',
            padding: '20px',
            justifyContent: 'center',
          }}
        >
          <IonIcon style={{ fontSize: '50px' }} slot="icon-only" icon={alertCircleOutline} />
          <h2 style={{ textAlign: 'center', color: '#ff3030' }}> Le Lien à expiré</h2>
        </div>

        <Footer type="button" title="Revenir à la carte" onClick={() => history.push('/')} />
      </Content>
    );

  return <>{children}</>;
}
