import firebase from 'firebase';
import get from 'lodash/get';

export const removeOrder = async ({ order }) => {
  const defaultFirestore = firebase.app();
  await defaultFirestore
    .firestore()
    .collection('orders')
    .doc(get(order, 'id'))
    .delete();
};
