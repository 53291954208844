import React from 'react';

export function Layout({ matches, children }) {
  if (matches.small) return <>{children}</>;
  return <DesktopLayout>{children}</DesktopLayout>;
}

function DesktopLayout({ children }) {
  return (
    <div className="main-background">
      <div style={{ width: '500px', position: 'relative', height: '700px' }}>{children}</div>
    </div>
  );
}
