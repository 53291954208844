import React from 'react';
import { arrowForwardOutline } from 'ionicons/icons';
import { useTheme } from 'src/modules/card/context/theme';
import { Icon, TextConsultation } from './utils';

export function ShowMore() {
  const theme = useTheme();
  return (
    <div style={{ display: 'flex', alignItems: 'center', height: '6vh' }}>
      <TextConsultation data-cy="unavailable-product" color={theme?.color?.productTitle}>
        Consulter
      </TextConsultation>
      <Icon color={theme?.color?.productTitle} icon={arrowForwardOutline} fontSize="1em" mode="ios" />
    </div>
  );
}
