import React from 'react';
import { get } from 'lodash';
import { IonFooter, IonToolbar, IonButton, IonText, IonRow, IonSkeletonText, IonSpinner } from '@ionic/react';
import styled from 'styled-components';
import { useTheme } from 'src/modules/card/context/theme';
import { colors } from '@bit/kards.kards-components.helpers';
import { useReadContext } from 'src/modules/card/context';
import { useBasketContext } from 'src/modules/basket';

type FooterProps = {
  loading: boolean;
  nbProducts: number;
  title: string;
  price: string;
  modal: boolean;
} & any;

export const Footer = React.memo(({ loading, displayNumber, title, price, displayPrice, modal, ...props }: FooterProps) => {
  const theme = useTheme();
  const { start, noOrder, mode }: any = useReadContext();
  const { nbProducts, totalPrice: totalPriceFromContext } = useBasketContext();

  const priceToDisplay = price ||totalPriceFromContext

  if (noOrder) return <div />;

  if (!start && mode !== "preorder") {
    return (
      <StyledFooter data-cy="footer">
        <Toolbar data-cy="btn-screen-off" color={get(theme, 'color.backgroundFooter')}>
          <IonRow className="ion-justify-content-center ion-align-items-center">
            <IonText color={get(theme, 'color.buttonOrder')}>Établissement actuellement fermé</IonText>
          </IonRow>
        </Toolbar>
      </StyledFooter>
    );
  }

  if (!theme) {
    return (
      <StyledFooter>
        <IonRow className="ion-justify-content-center ion-align-items-center">
          <IonSkeletonText animated style={{ height: '10vh', width: '100%%' }} />
        </IonRow>
      </StyledFooter>
    );
  }

  return (
    <StyledFooter>
      <Toolbar data-cy={`footer-${props["data-cy"]}`} color={theme?.color?.backgroundFooter}>
        <IonRow className="ion-justify-content-center ion-align-items-center">
          <Button {...props} expand="full" color={theme?.color?.backgroundFooter}>
            <div style={{ display: 'none' }} id="submit-button" />
            <div
              style={{
                flex: 0.3,
                display: 'flex',
                justifyContent: 'flex-start',
                alignItems: 'center',
              }}
            >
              {displayNumber && nbProducts >= 0 && (
                <BubbleNumber>
                  {loading ? (
                    <IonSpinner style={{ color: colors[theme?.color?.title] }} name="lines-small" />
                  ) : (
                    <IonText color={get(theme, 'color.buttonOrder')}>{nbProducts}</IonText>
                  )}
                </BubbleNumber>
              )}
            </div>
            <div
              style={{
                flex: 0.4,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <IonText style={{ fontSize: '1em' }} color={theme?.color?.buttonOrder}>
                {loading ? 'Chargement ...' : title}
              </IonText>
            </div>
            <div
              style={{
                flex: 0.3,
                display: 'flex',
                justifyContent: 'flex-end',
                alignItems: 'center',
              }}
            >
              {displayPrice && (
                <IonText style={{ fontSize: '1.4em', textAlign: 'right' }} color={theme?.color?.buttonOrder}>
                  {priceToDisplay}
                </IonText>
              )}
            </div>
          </Button>
        </IonRow>
      </Toolbar>
    </StyledFooter>
  );
})

const Button = styled(IonButton)`
  width: 98%;
  --box-shadow: none !important;
`;

const Toolbar = styled(IonToolbar)`
  background-color: ${props => props.color};
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;

const BubbleNumber = styled.div`
  display: flex;
  width: 2em;
  height: 2em;
  border-radius: 50%;
  background-color: rgba(100, 100, 100, 0.3);
  justify-content: center;
  align-items: center;
`;

const StyledFooter = styled(IonFooter)`
  position: fixed;
  z-index: 1000;
  box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.15);
  bottom: 0px;
  & ::before {
    display: none;
  }
`;
