import React from 'react';
import { alertCircleOutline } from 'ionicons/icons';
import { IonIcon, IonContent, IonButton } from '@ionic/react';


const ErrorType = ({ error }) => {
  console.log('--- ErrorType ----', error)
  if(error.type === "stock") return (
    <>
      <p style={{ padding: '10px', color: '#ff3030'}}>La commande n'a pus aboutir</p>
      {error.data?.missingProducts?.map((product)=> {
        return <p style={{ padding: '10px', color: '#ff3030'}}>Il ne reste plus de {product.reference}</p>
      })}
    </>
  )
  if(error.type === "StripeCardError") {
    return <p style={{ padding: '10px', color: '#ff3030'}}>Votre carte à était rejeté, Veuillez réessayer ou changer de carte</p>
  }

  return <p style={{ padding: '10px', color: '#ff3030'}}>Une erreur semble s'être produit</p>


}
export const Error = ({ error, resetError = () => null }) => (
  <IonContent style={{
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  }}
  >
    <div style={{
      textAlign: 'center',
      color: '#ff3030',
      display: 'flex',
      alignItems: 'center',
      flexDirection: 'column',
      padding: '20px',
      justifyContent: 'center'}}
    >
      <IonIcon style={{ fontSize: '50px' }} slot="icon-only" icon={alertCircleOutline} />
      <h2 style={{ textAlign: 'center', color: '#ff3030' }}>Oups</h2>
    </div>
    <div style={{
      textAlign: 'center',
      color: '#ff3030',
      display: 'flex',
      alignItems: 'center',
      flexDirection: 'column',
      padding: '20px',
      justifyContent: 'center'}}
      data-cy="text-error"
    >
      <ErrorType  error={error} />
      <IonButton color="dark" onClick={()=> resetError()}>Retour</IonButton>
    </div>
  </IonContent>
);
