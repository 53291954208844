import { IonIcon, IonText, IonButton } from '@ionic/react';
import styled from 'styled-components';

export const Icon = styled(IonIcon)`
  font-size: ${props => props.fontSize};
`;

export const Text = styled(IonText)`
  font-size: 1.3em;
  min-width: 1.5em;
  text-align: center;
`;

export const TextNotAvailable = styled(IonText)`
  font-size: 0.8em;
  text-align: start;
`;

export const TextConsultation = styled(IonText)`
  font-size: 0.9em;
  text-align: start;
  margin-right: 5px;
`;

export const Button = styled(IonButton)`
  font-size: 1.1em;
  margin: -1em;
  padding: 0;
`;

export const Row = styled.div`
  display: flex;
  padding-top: 6px;
  height: 2.5em;
  align-items: center;
`;
