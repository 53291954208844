import React from 'react';
import { useBasketContext } from 'src/modules/basket';
import { useReadContext } from 'src/modules/card/context';

import { AddRemoveProductUI } from './AddRemoveProductUI';
import { Unavailable } from './Unavailable';
import { ShowMore } from './ShowMore';

type AddRemoveBasketProps = {
  id?: string;
};

export function AddRemoveBasket({ id }: AddRemoveBasketProps) {
  const { getProduct, isOffertDisponible } = useBasketContext();
  const { noOrder }: any = useReadContext();

  const product = getProduct(id);

  const noStock = product?.type === 'product' ? !product?.stock?.unlimited && product?.stock?.stockNumber <= 0 : false;
  const offerDisponibility = isOffertDisponible(product);
  const isUnavailable = noStock || !offerDisponibility;

  const showMore = product?.description?.length >= 60 || product?.settingFields?.length > 0;

  if (noOrder) return <div />;
  if (isUnavailable) return <Unavailable />;
  if (showMore) return <ShowMore />;
  return <AddRemoveProductButton id={id} />;
}

type AddRemoveBasketRecapProps = {
  id: string;
};

export function AddRemoveBasketRecap({ id }: AddRemoveBasketRecapProps) {
  const { getBasketById } = useBasketContext();
  const showMore = getBasketById(id)?.settingFields?.length > 0;

  if (showMore) return <ShowMore />;
  return <AddRemoveProductButton id={id} />;
}

function AddRemoveProductButton({ id }: { id: string }) {
  const { getBasketById, updateBaskets } = useBasketContext();

  const basket = getBasketById(id);
  const quantity = basket?.quantity || 0;
  const unlimited = basket?.stock?.unlimited;
  const stock = basket?.stock?.stockNumber;
  const noStock = basket?.type === 'product' ? !unlimited && quantity >= stock : false;

  const less = e => {
    e.preventDefault();
    updateBaskets(id, b => ({ quantity: b.quantity <= 0 ? 0 : b.quantity - 1 }));
  };

  const more = e => {
    e.preventDefault();
    updateBaskets(id, b => {
      if (unlimited) return { quantity: b.quantity + 1 };
      return { quantity: b.quantity >= stock ? b.quantity : b.quantity + 1 };
    });
  };

  return (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      <AddRemoveProductUI
        onClickLess={less}
        onClickMore={more}
        disabledLess={quantity === 0}
        disabledMore={noStock}
        style={{ height: '6vh' }}
      >
        {quantity}
      </AddRemoveProductUI>
    </div>
  );
}
