import React, { useState } from 'react';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import FormAddNewCard from './FormAddNewCard';
import FormPaidWithNewCard from './FormPaidWithNewCard';
import FormPaidWithRegisterCard from './FormPaidWithRegisterCard';
import { useCreditCards } from '../../data/hooks/useCreditCards';
import Loading from './Loading';
import { CheckoutFormProps } from './CheckoutForm';
import { GooglePayButton, GooglePayButtonProps } from './GooglePayButton';

const stripePromise = loadStripe((window as any).kardsConfiguration.stripePk);

type FormPaymentProps = {
  type: 'card' | 'googlePay' | 'applePay';
  googlePayInfos: GooglePayButtonProps['googlePayInfos'];
  onSubmitRegister?: CheckoutFormProps['onSubmit'];
  onSubmitNewCard?: CheckoutFormProps['onSubmit'];
} & any;

export function FormPayment({ type = 'card', googlePayInfos, onSubmitRegister, onSubmitNewCard, ...props }: FormPaymentProps) {
  return (
    <Elements stripe={stripePromise}>
      {['googlePay', 'applePay'].includes(type) && (
        <GooglePayButton googlePayInfos={googlePayInfos} onSubmit={onSubmitRegister} type={type}>
          <PaymentWorkFlow onSubmitRegister={onSubmitRegister} onSubmitNewCard={onSubmitRegister} {...props} />
        </GooglePayButton>
      )}
      {type === 'card' && <PaymentWorkFlow onSubmitRegister={onSubmitRegister} onSubmitNewCard={onSubmitRegister} {...props} />}
    </Elements>
  );
}

function PaymentWorkFlow({ onSubmitRegister, onSubmitNewCard, ...props }) {
  const { data, loadingCreditCard, setData } = useCreditCards();
  const [step, setStep] = useState('paid');

  return (
    <>
      {(loadingCreditCard || props.loading) && <Loading text="Chargement de vos cartes" />}
      {step === 'paid' && !loadingCreditCard && data.length > 0 && (
        <FormPaidWithRegisterCard onSubmit={onSubmitRegister} setCreditCards={setData} creditCards={data} setStep={setStep} {...props} />
      )}
      {step === 'paid' && !loadingCreditCard && data.length === 0 && <FormPaidWithNewCard onSubmit={onSubmitNewCard} {...props} />}
      {step === 'new' && <FormAddNewCard setCreditCards={setData} setStep={setStep} creditCards={data} />}
    </>
  );
}
