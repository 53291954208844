import { useEffect, useState } from 'react';
import firebase from 'firebase';

export const orderHistory = ({ userId }) => {
  const defaultFirestore = firebase.app();
  if (userId) {
    return defaultFirestore
      .firestore()
      .collection('orders')
      .where('userId', '==', userId)
      .orderBy('created_at', 'desc')
      .get();
  }
};

export const useOrderHistory = ({ userId }) => {
  const [orders, setOrders] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (userId) {
      orderHistory({ userId }).then((data:any) => {
        const orderDeliveredData = data.docs.map((doc) => doc.data());

        setOrders(orderDeliveredData);
        setLoading(false);
      });
    }
  }, [userId]);
  return { data: orders, loading };
};
