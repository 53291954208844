import React, { useState, useEffect } from 'react';
import { PaymentRequestButtonElement, useStripe } from '@stripe/react-stripe-js';
import { Content, Text } from 'src/common-ui';

export type GooglePayButtonProps = {
  type: 'googlePay' | 'applePay';
  googlePayInfos: { label: string; amount: number };
  onSubmit: (value: { paymentMethod: any }) => Promise<any>;
  children: React.ReactNode;
};

export function GooglePayButton({
  type,
  googlePayInfos = {
    label: 'Kards',
    amount: 100,
  },
  onSubmit = () => Promise.resolve(),
  children,
}: GooglePayButtonProps) {
  const stripe = useStripe();
  const [paymentRequest, setPaymentRequest] = useState(null);

  useEffect(() => {
    if (stripe) {
      const pr = stripe.paymentRequest({
        country: 'FR',
        currency: 'eur',
        total: googlePayInfos,
        requestPayerName: true,
        requestPayerEmail: true,
      });

      pr.on('paymentmethod', async ev => {
        await onSubmit({ paymentMethod: ev.paymentMethod.id });
        ev.complete('success');
      });

      // Check the availability of the Payment Request API.
      pr.canMakePayment().then(result => {
        if (result) {
          setPaymentRequest(pr);
        }
      });
    }
  }, [stripe]);

  if (paymentRequest) {
    return (
      <Content>
        <div style={{ display: 'flex', justifyContent: 'center', width: '100%', height: '100%', alignItems: 'center' }}>
          <div style={{ width: '250px' }}>
            <PaymentRequestButtonElement options={{ paymentRequest }} />
          </div>
        </div>
      </Content>
    );
  }
  const icon = { googlePay: '/img/icon/googlePay.svg', applePay: '/img/icon/applePay.svg' }[type];
  const name = { googlePay: 'Google Pay', applePay: 'Apple Pay' }[type];

  return (
    <>
      <div style={{ zIndex: 3, marginBottom: '-30px' }}>
        <Text justifyContent="center">Problème de chargement de {name}</Text>
        <div style={{ display: 'flex', justifyContent: 'center', marginTop: '10px' }}>
          <img src={icon} width="50px" />
        </div>
      </div>
      {children}
    </>
  );
}
