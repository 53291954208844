import React, { useEffect, useState } from 'react';
import { findIndex, get, forEach, map, find } from 'lodash';
import { IonCheckbox, IonItemDivider, IonItem, IonLabel } from '@ionic/react';
import styled from 'styled-components';
import { Field, Formik } from 'formik';
import { Form } from 'src/modules/product/Form';
import { useTheme } from 'src/modules/card/context/theme';
import { useHistory, useLocation, useParams, Redirect } from 'react-router-dom';
import { colors } from '@bit/kards.kards-components.helpers';
import { Content } from 'src/common-ui';
import { useBasketContext } from '../basket/context/BasketContext';
import { useReadContext } from '../card/context';
import { ModalProduct } from './ModalAddProduct';

const Text = styled.div`
  text-align: ${props => props.textAlign};
  width: ${props => props.width};
  color: ${props => props.color};
  font-size: ${props => props.fontSize};
  font-weight: ${props => props.fontWeight};
  font-family: ${props => props.family};
  padding: ${props => props.padding};
  padding-bottom: ${props => props.paddingBottom};
  padding-top: ${props => props.paddingTop};
  white-space: ${props => props.whiteSpace};
  line-height: ${props => props.lineHeight};
  text-decoration: ${props => props.textDecoration};
  display: ${props => props.display};
  justify-content: center;
  align-items: center;
`;

const IonCheckboxWrapper = styled(IonCheckbox)`
  --background: ${props => colors[props.background]};
  --border-color: ${props => colors[props.borderColor]};
`;

const ContainerRadio = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;

const Radio = ({ options, onChange, value, error, setOpen, setOnChange, disable }) => {
  const theme = useTheme();

  useEffect(() => {
    if (!value && options[0]) {
      onChange(options[0].value);
    }
  }, []);

  return (
    <div data-cy="radio-input" style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
      {map(options, opt => (
        <>
          <IonItem
            onClick={() => {
              setOnChange(() => onChange);
              setOpen(opt);
            }}
            key={opt.id}
            lines="none"
            color={get(theme, 'color.background')}
          >
            <ContainerRadio data-cy="radio-input">
              <IonLabel color={get(theme, 'color.productTitle')} style={{ whiteSpace: 'break-spaces', flex: '.8' }}>
                {opt.reference}
              </IonLabel>
              {!get(opt, 'stock.unlimited') && parseInt(get(opt, 'stock.stockNumber'), 10) < 1 && (
                <IonLabel style={{ whiteSpace: 'break-spaces', color: 'grey' }}>indisponible</IonLabel>
              )}
              {!disable && (
                <IonCheckboxWrapper
                  disabled={!get(opt, 'stock.unlimited') && parseInt(get(opt, 'stock.stockNumber'), 10) < 1}
                  slot="end"
                  mode="md"
                  color={get(theme, 'color.backgroundPrice')}
                  borderColor={get(theme, 'color.backgroundPrice')}
                  background={get(theme, 'color.background')}
                  checked={get(value, 'id') === opt.id && !disable}
                />
              )}
            </ContainerRadio>
          </IonItem>
          <IonItemDivider mode="md" color={get(theme, 'color.background')} style={{ '--padding-start': 0, minHeight: '0px' }} />
        </>
      ))}
      {error && !disable && <div style={{ color: 'red', fontSize: '.8em' }}>{error}</div>}
    </div>
  );
};

const Select = ({ options, onChange, value, error, setOpen, setOnChange, disable }) => {
  const theme = useTheme();

  useEffect(() => {
    if (!value) {
      onChange([]);
    }
  }, []);

  const handleChange = val => {
    const index = findIndex(value, (e: any) => e.id === val.id);
    if (index > 0) {
      value.splice(index, 1);
    } else {
      value.push(val);
    }
    onChange(value);
  };

  return (
    <div data-cy="select-input" style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
      {map(options, opt => (
        <>
          <IonItem
            onClick={() => {
              const index = findIndex(value, (e: any) => e.id === opt.id);
              if (index < 0) {
                setOnChange(() => handleChange);
                setOpen(opt);
                return;
              }
              value.splice(index, 1);
              onChange(value);
            }}
            key={opt.id}
            lines="none"
            color={get(theme, 'color.background')}
          >
            <ContainerRadio data-cy="radio-input">
              <IonLabel color={get(theme, 'color.productTitle')} style={{ whiteSpace: 'break-spaces', flex: '.8' }}>
                {opt.reference}
              </IonLabel>
              {!get(opt, 'stock.unlimited') && parseInt(get(opt, 'stock.stockNumber'), 10) < 1 && (
                <IonLabel style={{ whiteSpace: 'break-spaces', color: 'grey' }}>indisponible</IonLabel>
              )}
              {!disable && (
                <IonCheckboxWrapper
                  disabled={!get(opt, 'stock.unlimited') && parseInt(get(opt, 'stock.stockNumber'), 10) < 1}
                  slot="end"
                  mode="md"
                  color={get(theme, 'color.backgroundPrice')}
                  borderColor={get(theme, 'color.backgroundPrice')}
                  background={get(theme, 'color.background')}
                  checked={find(value, (e: any) => e.id === opt.id) && !disable}
                />
              )}
            </ContainerRadio>
          </IonItem>
          <IonItemDivider mode="md" color={get(theme, 'color.background')} style={{ '--padding-start': 0, minHeight: '0px' }} />
        </>
      ))}
      {error && !disable && <div style={{ color: 'red' }}>{error}</div>}
    </div>
  );
};

const OfferComponent = ({ options, optionsNumber, onChange, value, error, setOpen, setOnChange }) => {
  const { noOrder }: any = useReadContext();
  return optionsNumber > 1 ? (
    <Select
      setOpen={setOpen}
      setOnChange={setOnChange}
      error={error}
      onChange={onChange}
      value={value}
      options={options}
      disable={noOrder}
    />
  ) : (
    <Radio
      setOpen={setOpen}
      setOnChange={setOnChange}
      data-cy=""
      error={error}
      onChange={onChange}
      value={value}
      options={options}
      disable={noOrder}
    />
  );
};

const SettingField = ({ settingField }) => {
  const theme = useTheme();
  const [open, setOpen] = useState();
  const [onChange, setOnchange] = useState();

  return (
    <div key={settingField.label} style={{ background: colors[get(theme, 'color.background')], padding: '1em' }}>
      <ModalProduct open={open} setOpen={setOpen} onChange={onChange} />
      <IonItemDivider mode="md" color={get(theme, 'color.background')} style={{ '--padding-start': 0 }}>
        <Text color={colors[get(theme, 'color.title')]} fontSize="1.2em" fontWeight="bold">
          {settingField.label}
        </Text>
      </IonItemDivider>
      <Field name={`options.${settingField.label}`} placeholder={settingField.label} required>
        {({ field, form, meta }) => (
          <IonItem lines="none" key={settingField.id} color={get(theme, 'color.background')}>
            <OfferComponent
              onChange={value => {
                form.setFieldValue(field.name, value);
              }}
              setOnChange={setOnchange}
              setOpen={setOpen}
              error={meta.error}
              value={field.value}
              options={settingField.products}
              optionsNumber={settingField.optionsNumber}
            />
          </IonItem>
        )}
      </Field>
    </div>
  );
};

export default function Offer() {
  const { addBasketToBaskets, updateBasketsByOrderProductId }: any = useBasketContext();
  const { state } = useLocation();
  const history = useHistory();
  const { id } = useParams();

  const offer: any = get(state, 'offer');
  if (!offer) return <Redirect to="/" />;

  const validate = values => {
    const errors: any = {};
    if (values.options) {
      forEach(values.options, (option, i) => {
        forEach(offer.settingFields, champ => {
          if (!Object.keys(values.options).includes(champ.label)) {
            if (champ.required) {
              if (!errors.options) {
                errors.options = {};
              }
              errors.options[champ.label] = 'Champs requis';
            }
          }
          if (champ.label === i) {
            if (option && option.length > champ.optionsNumber) {
              if (!errors.options) {
                errors.options = {};
              }
              errors.options[i] = `Maximum ${champ.optionsNumber} choix`;
            }
          }
        });
      });
    } else {
      offer.settingFields.forEach(champ => {
        if (champ.required) {
          if (!errors.options) {
            errors.options = {};
          }
          errors.options[champ.label] = 'Champs requis';
        }
      });
    }

    return errors;
  };

  const initialValues = {
    id: offer.id,
    settingFields: offer.settingFields,
    options: offer.options,
    quantity: offer.quantity ? offer.quantity : 1,
  };

  return (
    <Content>
      <Formik
        initialValues={initialValues}
        validateOnMount
        onSubmit={values => {
          if (id) {
            updateBasketsByOrderProductId(id, () => ({ options: values.options, quantity: values.quantity }));
          } else {
            addBasketToBaskets(values.id, { options: values.options, quantity: values.quantity });
          }
          history.goBack();
        }}
        validate={validate}
      >
        {({ errors }) => <Form entity={offer} errors={errors} SettingsFieldComponent={SettingField} />}
      </Formik>
    </Content>
  );
}
