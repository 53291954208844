/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';
import firebase from 'firebase';
import { filter } from 'lodash';

const orderPrepared = () => {
  const defaultFirestore = firebase.app();
  return defaultFirestore
    .firestore()
    .collection('orders')
    .where('status.S3', '==', true)
    .orderBy('created_at', 'desc')
    .get();
};

export const useTimeOrder = ({ initialTime, averageTime }) => {
  const [time, setTime] = useState(averageTime);
  useEffect(() => {
    orderPrepared().then((data:any) => {
      const orderDeliveredData = data.docs.map((doc) => doc.data());
      const count = filter(orderDeliveredData, (o) => o.created_at < initialTime);
      if (count.length > 0) {
        const average:any = Number(averageTime);
        const time = average * count.length;
        setTime(time);
      }
    });
  }, []);

  return { data: time };
};
