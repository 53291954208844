import React from 'react';
import { Content } from 'src/common-ui';
import { Formik, Form, FormikConfig } from 'formik';

type FormikLayoutProps<T> = {
  children: React.ReactNode;
  style?: React.CSSProperties;
} & FormikConfig<T>;

export function FormikLayout<T>({ children, style = {}, ...props }: FormikLayoutProps<T>) {
  return (
    <Content fullscreen>
      <Formik {...props}>
        <Form style={{ width: '100%' }}>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
              paddingBottom: '100px',
              ...style,
            }}
          >
            {children}
          </div>
        </Form>
      </Formik>
    </Content>
  );
}
