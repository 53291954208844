import { useEffect, useState } from 'react';


export function useQuery<T>(apiClient: () => Promise<{ data: () => T }>) {
  const [data, setData] = useState<T | undefined>();
  const [error, setError] = useState();

  useEffect(() => {
    apiClient()
      .then((result) => setData(result.data()))
      .catch(setError);
  }, []);

  return { data, error };
}
