import React from 'react';
import { arrowUpCircleOutline, arrowDownCircleOutline } from 'ionicons/icons';
import { useTheme } from 'src/modules/card/context/theme';
import { Row, Button, Icon, Text } from './utils';

type AddRemoveProductUIProps = {
  onClickLess?: any;
  onClickMore?: any;
  disabledLess?: boolean;
  disabledMore?: boolean;
  style?: any;
  children?: any;
};

export function AddRemoveProductUI({ children, onClickLess, onClickMore, disabledLess, disabledMore, style }: AddRemoveProductUIProps) {
  const theme = useTheme();

  return (
    <Row style={style}>
      <Button color={theme?.color?.title} fill="clear" onClick={onClickLess} disabled={disabledLess}>
        <Icon color={theme?.color?.productTitle} icon={arrowDownCircleOutline} fontSize="1.7em" mode="ios" />
      </Button>
      <Text color={theme?.color?.productTitle}>{children}</Text>
      <Button color={theme?.color?.title} fill="clear" onClick={onClickMore} disabled={disabledMore} data-cy="add-item">
        <Icon color={theme?.color?.productTitle} icon={arrowUpCircleOutline} mode="ios" fontSize="1.7em" />
      </Button>
    </Row>
  );
}
