import React, { useContext } from 'react';
import CategoryWithText from '@bit/kards.kards-components.categories-header-text';
import { ReadContext } from 'src/modules/card/context';
import { ThemeContext } from 'src/modules/card/context/theme';

const CategoryList = () => {
  const {
    cardData,
    setCurrentCategory,
    currentCategory,
  }: any = useContext(ReadContext);
  const {
    theme,
  }: any = useContext(ThemeContext);

  return (
    <CategoryWithText.component
      theme={theme}
      currentCategory={currentCategory}
      setCurrentCategory={setCurrentCategory}
      data={cardData}
    />
  );
};

export default CategoryList;
