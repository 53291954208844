import React, { useContext } from 'react';
import ImageSlides from '@bit/kards.kards-components.image-slides';
import { ThemeContext } from 'src/modules/card/context/theme';

export default ({ data }) => {
  const {
    theme,
  }: any = useContext(ThemeContext);

  return (
    <ImageSlides.component
      theme={theme}
      data={data}
    />
  );
};
