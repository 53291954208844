import React, { useEffect, useContext, useState } from 'react';
import firebase from 'firebase';
import styled from 'styled-components';
import { IonIcon } from '@ionic/react';
import { colors } from '@bit/kards.kards-components.helpers';
import { get } from 'lodash';
import { lockOpen, lockClosed } from 'ionicons/icons';
import ReactPhoneInput from 'react-phone-input-2';
import { isValidPhoneNumber } from 'react-phone-number-input';
import { configuration } from 'src/App';
import { ThemeContext } from 'src/modules/card/context/theme';
import 'react-phone-input-2/lib/style.css';
import { Content, Footer } from 'src/common-ui';

const Input = styled.input`
  position: absolute;
  border: none;
  font-size: 32px;
  text-align: center;
  background-color: transparent;
`;

const Division = styled.div`
  border-right: 1px solid ${props => props.border};
  width: 32px;
  height: 58px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 32px;
  position: relative;
`;

const Wrap = styled.div`
  border: 1px solid ${props => props.border};
  display: inline-block;
  position: relative;
  display: flex;
`;

const Text = styled.div`
  text-align: ${props => props.textAlign};
  width: ${props => props.width};
  color: ${props => props.color};
  font-size: ${props => props.fontSize};
  font-weight: ${props => props.fontWeight};
  font-family: ${props => props.family};
  padding: ${props => props.padding};
  padding-bottom: ${props => props.paddingBottom};
  padding-top: ${props => props.paddingTop};
  white-space: ${props => props.whiteSpace};
  line-height: ${props => props.lineHeight};
  text-decoration: ${props => props.textDecoration};
  display: ${props => props.display};
  justify-content: center;
  align-items: center;
`;

const IonIconWrapper = styled(IonIcon)`
  font-size: ${props => props.fontSize};
  padding-top: 1em;
`;

export default function Authentification() {
  const [confirmationResult, setConfirmationResult] = useState(null);
  const [phoneNumber, setPhoneNumber] = useState('');
  const [code, setCode] = useState('');
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);
  const typedWindow: any = window;

  useEffect(() => {
    if (configuration.testMode) {
      firebase.auth().settings.appVerificationDisabledForTesting = true;
      typedWindow.recaptchaVerifier = new firebase.auth.RecaptchaVerifier('submit-button');
    } else {
      typedWindow.recaptchaVerifier = new firebase.auth.RecaptchaVerifier('submit-button', {
        size: 'invisible',
      });
    }
  }, []);

  const signIn = () => {
    const phoneNumberFormat = `+${phoneNumber}`;
    const appVerifier = typedWindow.recaptchaVerifier;
    setLoading(true);
    if (confirmationResult) {
      return confirmationResult
        .confirm(code)
        .then(async () => {
          // populate commande
          setLoading(false);
        })
        .catch(() => {
          setCode('');
          setLoading(false);
          setError(true);
        });
    }

    firebase
      .auth()
      .signInWithPhoneNumber(phoneNumberFormat, appVerifier)
      .then(confirmationResult => {
        setConfirmationResult(confirmationResult);
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
        setError(true);
      });
  };

  useEffect(() => {
    if (code.length === 6) {
      signIn();
    }
  }, [phoneNumber, code]);

  return (
    <Content>
      <div style={{ flex: 1, display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'center' }}>
        {confirmationResult ? (
          <InputCode error={error} setConfirmationResult={setConfirmationResult} values={code} setValue={setCode} />
        ) : (
          <Phone error={error} setError={setError} setValue={setPhoneNumber} values={phoneNumber} />
        )}
      </div>
      <Footer data-cy="btn-auth" title="Confirmer" type="button" onClick={() => signIn()} disabled={loading || error} loading={loading} />
    </Content>
  );
}

const InputCode = ({ error, values, setValue, setConfirmationResult }) => {
  const CODE_LENGTH = new Array(6).fill(0);
  const { theme }: any = useContext(ThemeContext);
  const selectedIndex = values.length < CODE_LENGTH.length ? values.length : CODE_LENGTH.length - 1;

  const handleChange = e => {
    const { value } = e.target;
    if (values.length >= CODE_LENGTH.length) return null;
    setValue((values + value).slice(0, CODE_LENGTH.length));
  };

  const handleDelete = e => {
    if (e.key === 'Backspace') {
      setValue(values.slice(0, values.length - 1));
    }
  };

  const hideInput = !(values.length < CODE_LENGTH.length);

  return (
    <div
      style={{
        height: '100%',
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          flexDirection: 'column',
        }}
      >
        <IonIconWrapper fontSize="2.5em" color={get(theme, 'color.title')} icon={lockOpen} />
        <Text color={colors[get(theme, 'color.title')]} padding="2em" fontSize="1.2em" textAlign="center">
          Pour finir, saisissez le code de vérification à 6 chiffres qui vous a été envoyé par sms.
        </Text>
      </div>
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          flexDirection: 'column',
        }}
      >
        <Wrap border={colors[get(theme, 'color.title')]}>
          <>
            {CODE_LENGTH.map((v, index) => (
              <Division border={colors[get(theme, 'color.title')]}>{values[index]}</Division>
            ))}
            <Input
              type="number"
              value=""
              onChange={handleChange}
              onKeyUp={handleDelete}
              className="input"
              data-cy="input-code"
              style={{
                width: '32px',
                top: '0px',
                bottom: '0px',
                color: colors[get(theme, 'color.title')],
                left: `${selectedIndex * 32}px`,
                opacity: hideInput ? 0 : 1,
                outline: 'auto',
              }}
            />
          </>
        </Wrap>
        <Text color={colors[get(theme, 'color.title')]} padding=".5em" fontSize="1.2em" textAlign="center" fontWeight="bold">
          Votre code
        </Text>
        {error && <span style={{ color: 'rgb(205, 61, 100)' }}>{"Une erreur semble s'être produite"}</span>}
        <Text
          onClick={() => setConfirmationResult(null)}
          color={colors[get(theme, 'color.title')]}
          padding="1em"
          fontSize=".9em"
          textAlign="center"
          textDecoration="underline"
        >
          Un problème avec votre code ?
        </Text>
      </div>
    </div>
  );
};

const Phone = ({ setValue, values, setError, error }) => {
  const { theme }: any = useContext(ThemeContext);
  useEffect(() => {
    setError(!isValidPhoneNumber(`+${values}`));
    setError(!isValidPhoneNumber(`+${values}`));
  }, [values]);

  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
      }}
    >
      <IonIconWrapper fontSize="2.5em" color={get(theme, 'color.title')} icon={lockClosed} />
      <Text color={colors[get(theme, 'color.title')]} padding="2em" fontSize="1.2em" textAlign="center">
        Pour sécuriser votre paiement, veuillez saisir votre numéro de téléphone.
      </Text>
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          flexDirection: 'column',
          marginBottom: '25vh',
        }}
      >
        <ReactPhoneInput
          inputStyle={{
            color: '#222428',
            fontSize: '1.1em',
            height: '2.5em',
          }}
          data-cy="phone-number"
          country="fr"
          onlyCountries={['fr']}
          value={values}
          onChange={e => {
            setValue(e);
          }}
        />
        <span style={{ color: 'rgb(205, 61, 100)', fontSize: '1em', padding: '.4em' }}>{error && 'Numéro incorrect'}</span>
      </div>
    </div>
  );
};
