import { useEffect, useState } from 'react';
import localforage from 'localforage';

export const useLocalForage = ({ key }) => {
  const [item, setItem] = useState([]);
  useEffect(() => {
    if (key) {
      localforage.getItem(key).then((data: any) => {
        setItem(data);
      });
    }
  }, [key]);

  return item;
};
