import React, { useContext } from 'react';
import Video from '@bit/kards.kards-components.video';
import { ThemeContext } from 'src/modules/card/context/theme';

export default ({ data: { title, description, video } }) => {
  const {
    theme,
  }: any = useContext(ThemeContext);

  return (
    <Video.component
      theme={theme}
      title={title}
      description={description}
      video={video}
    />
  );
};
