import React, { createContext, useContext, useMemo } from 'react';
import { v4 as uuid } from 'uuid';
import { useLocalStorage } from 'src/data/hooks/useLocalStorage';
import { useReadContext } from 'src/modules/card/context';
import { getTotalPrice, getNbProducts, isOffertDisponible, getProducts, productToBasket } from './utils';
import { Basket } from '../types';

type ContextType = {
  baskets: Basket[];
  amount: number;
  totalPrice: string;
  nbProducts: number;
  isOffertDisponible: (basket: Basket) => boolean;
  addToBaskets: (id: string, basket: Partial<Basket>) => void;
  getProduct: (id: string) => Basket;
  getBasketById: (id: string) => Basket;
  updateBaskets: (id: string, getBasketToUpdate: (basket: Basket) => Partial<Basket>) => void;
  updateBasketsByOrderProductId: (id: string, getBasketToUpdate: (basket: Basket) => Partial<Basket>) => void;
  resetBasketProduct: () => Promise<void>;
};

const BasketContext = createContext<ContextType>({} as ContextType);

export function useBasketContext() {
  return useContext(BasketContext);
}

function BasketProviderComponent({ children }) {
  const { cardData }: any = useReadContext();

  const products = useMemo(() => getProducts(cardData), []);

  const [baskets, setBaskets] = useLocalStorage<Basket[]>('baskets', (basketFromLocalStorage: Basket[]) => basketFromLocalStorage || []);

  const getProduct = (id: string) => products.find(b => b.id === id);
  const getBasketById = (id: string) => baskets.find(b => b.id === id);
  const pickedBaskets = baskets.filter(b => b.quantity > 0);

  const totalPrice = getTotalPrice(pickedBaskets);
  const nbProducts = getNbProducts(pickedBaskets);

  const addToBaskets = (id: string, basket: Partial<Basket>) => {
    const basketFromProduct = productToBasket(getProduct(id)) || {};
    setBaskets(bs => [...bs, { ...basketFromProduct, ...basket, orderProductId: uuid() }] as Basket[]);
  };

  const updateBaskets = (id: string, getBasketToUpdate: (basket: Basket) => Partial<Basket>) => {
    if (baskets.find(b => b.id === id)) {
      setBaskets(bs =>
        bs
          .map(basket => {
            if (basket.id === id) return { ...basket, ...getBasketToUpdate(basket) };
            return basket;
          })
          .filter(b => b.quantity > 0),
      );
    } else {
      addToBaskets(id, { quantity: 1 });
    }
  };

  const updateBasketsByOrderProductId = (id: string, getBasketToUpdate: (basket: Basket) => Partial<Basket>) => {
    setBaskets(bs =>
      bs
        .map(basket => {
          if (basket.orderProductId === id) return { ...basket, ...getBasketToUpdate(basket) };
          return basket;
        })
        .filter(b => b.quantity > 0),
    );
  };

  const resetBasketProduct = async () => {
    setBaskets([]);
  };

  const value = React.useMemo(
    () => ({
      getProduct,
      baskets: pickedBaskets,
      amount: totalPrice.getAmount(),
      totalPrice: totalPrice.setLocale('fr-FR').toFormat(),
      nbProducts,
      getBasketById,
      isOffertDisponible,

      addToBaskets,
      updateBasketsByOrderProductId,
      updateBaskets,
      resetBasketProduct,
    }),
    [baskets],
  );

  return <BasketContext.Provider value={value}>{children}</BasketContext.Provider>;
}

export const BasketProvider = React.memo(BasketProviderComponent);
