import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import { useReadContext } from 'src/modules/card/context';
import Order from 'src/modules/order';
import Product from 'src/modules/product';
import ViewOffer from 'src/modules/offer';
import Menu from 'src/modules/burgerMenu';
import History from 'src/modules/burgerMenu/components/Histories';
import Abouts from 'src/modules/burgerMenu/components/Abouts';
import Helps from 'src/modules/burgerMenu/components/Helps';
import HelpsDetail from 'src/modules/burgerMenu/components/HelpsDetail';
import Contact from 'src/modules/burgerMenu/components/Contact';
import Authentification from 'src/modules/authentification';
import CardRead from 'src/modules/card';
import { DivideBillRoutes } from './divide-bill';

export function PrivateRoute({ ...props }) {
  const { loadingUser, user, mode, noOrder, start }: any = useReadContext();

  if ((!start && mode === 'instant') || noOrder) return <Redirect to="/" />;
  if (loadingUser) return null;
  if (!user) return <Redirect to="/auth" />;

  return <Route {...props} />;
}

export const AuthRoute = ({ user, noOrder, ...rest }) => {
  if (noOrder) return <Redirect to="/" {...rest} />;
  if (user) return <Redirect to="/orders/confirm" {...rest} />;

  return <Route {...rest} />;
};

export const Routes = React.memo(() => {
  const { user, mode, noOrder, start }: any = useReadContext();

  return (
    <Switch>
      <AuthRoute user={user} path="/auth" noOrder={noOrder} exact>
        <Authentification />
      </AuthRoute>
      <PrivateRoute mode={mode} user={user} start={start} noOrder={noOrder} path="/orders">
        <Order />
      </PrivateRoute>

      <Route path="/bill">
        <DivideBillRoutes />
      </Route>

      <Route path="/product/:id?">
        <Product />
      </Route>
      <Route path="/offer/:id?" exact>
        <ViewOffer />
      </Route>

      <Route path="/menu" exact>
        <Menu />
      </Route>
      <Route path="/menu/history" exact>
        <History />
      </Route>
      <Route path="/menu/contacts" exact>
        <Contact />
      </Route>
      <Route path="/menu/about" exact>
        <Abouts />
      </Route>
      <Route path="/menu/help" exact>
        <Helps />
      </Route>
      <Route path="/menu/help/:id" exact>
        <HelpsDetail />
      </Route>

      <Route path="/">
        <CardRead />
      </Route>
    </Switch>
  );
});
