import { useEffect, useState } from 'react';
import firebase from 'firebase';
import { get, map } from 'lodash';
import { listUserCreditCards } from '../accessor/stripe';
import { useReadContext } from '../../modules/card/context/index';

export const orderHistory = async ({ userId }) => {
  const defaultFirestore = firebase.app();
  if (userId) {
    const history = await defaultFirestore
      .firestore()
      .collection('orders')
      .where('userId', '==', userId)
      .orderBy('created_at', 'desc')
      .get();
    return map(history.docs, doc => doc.data());
  }
};

export const useCreditCards = () => {
  const { user }: any = useReadContext();
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const listCard = async () => {
      if (!user) {
        setLoading(false);
        return;
      }

      const orders = await orderHistory({ userId: get(user, 'uid') });
      if (!(orders && orders.length > 0)) {
        setLoading(false);
        return;
      }
      const { data }: any = await listUserCreditCards();
      const cards = map(data?.data, creditCard => {
        return {
          ...creditCard.card,
          payment_method_id: creditCard.id,
          name: creditCard.metadata.name,
        };
      });
      setData(cards);
      setLoading(false);
    };

    listCard();
  }, []);

  return { data, loadingCreditCard: loading, setData };
};
