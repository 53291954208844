import Dinero from 'dinero.js';
import React, { useState } from 'react';
import { IonList, IonItem, IonText, IonCol, IonRow, IonButton, IonContent } from '@ionic/react';
import { get, map } from 'lodash';
import styled from 'styled-components';
import { colors, getCurrentStep, S3, S4, S1, S2 } from '@bit/kards.kards-components.helpers';
import { useHistory } from 'react-router-dom';
import { useOrderHistory } from 'src/data/hooks/getHistory';
import { useReadContext } from 'src/modules/card/context';
import { useTheme } from 'src/modules/card/context/theme';
import { ModalResumeOrder } from '../../liveOrder/components/ModalResumeOrder';
import { useOrderPreparingContext } from 'src/modules/liveOrder/context';

const IonListWrapper = styled(IonList)`
  background-color: ${props => colors[props.bgColor]};
  margin: 1.5em;
  border-radius: 5px;
  box-shadow: 0px 0px 0px 2px ${props => colors[props.color]};
`;

const IonContentWrapper = styled(IonContent)`
  background-color: ${props => colors[props.color]};
  overflow: scroll;
`;

const IonItemWrapper = styled(IonItem)`
  padding: 5px;
  background-color: ${props => colors[props.color]};
  flex: 1;
`;

const IonTextPrice = styled(IonText)`
  border: ${props => colors[props.color]} solid 1px;
  border-radius: 15px;
  padding: 0 7px;
  font-size: 0.8em;
`;

const Bold = styled.span`
  font-weight: bold;
`;

const Text = styled.div`
  text-align: ${props => props.textAlign};
  width: ${props => props.width};
  color: ${props => props.color};
  font-size: ${props => props.fontSize};
  font-weight: ${props => props.fontWeight};
  font-family: ${props => props.family};
  padding: ${props => props.padding};
  padding-bottom: ${props => props.paddingBottom};
  padding-top: ${props => props.paddingTop};
  white-space: ${props => props.whiteSpace};
  line-height: ${props => props.lineHeight};
  text-decoration: ${props => props.textDecoration};
  display: ${props => props.display};
  justify-content: center;
  align-items: center;
`;

const Empty = styled.div`
  color: ${props => props.color};
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 2em;
  text-align: center;
  margin-bottom: 30vh;
  font-size: 1.2em;
`;

const formatNumber = number => {
  if (number < 10) return `0${number}`;
  return number;
};

const History = () => {
  const { user }: any = useReadContext();
  const theme = useTheme();
  const history = useHistory();
  const { data, loading } = useOrderHistory({ userId: get(user, 'uid') });

  return (
    <IonContentWrapper color={get(theme, 'color.background')} lines="none">
      {loading && <Empty color={colors[get(theme, 'color.title')]}> ...Chargement </Empty>}
      {map(data, (order, i) => (
        <RenderListOrder key={get(order, 'key')} order={order} i={i} />
      ))}
      {!loading && data.length < 1 && <Empty color={colors[get(theme, 'color.title')]}>{"Vous n'avez pas encore commandé."}</Empty>}
      {!user && (
        <div
          style={{
            border: `solid 1px${colors[get(theme, 'color.title')]}`,
            margin: '2em',
            borderRadius: '5px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'column',
          }}
        >
          <Text color={colors[get(theme, 'color.title')]} padding="1em" textAlign="center" fontSize=".9em">
            {"Vous n'etes pas encore connecté"}
          </Text>
          <IonButton
            color={get(theme, 'color.title')}
            size="small"
            style={{
              marginBottom: '1em',
              fontSize: '1em',
              '--border-radius': '30px',
            }}
            onClick={() => history.push('/auth')}
          >
            Me connecter
          </IonButton>
        </div>
      )}
      <div style={{ height: '15vh' }} />
    </IonContentWrapper>
  );
};

export default History;

const RenderListOrder = ({ order, i }) => {
  const { followOrder }: any = useOrderPreparingContext();
  const theme = useTheme();
  const [open, setOpen] = useState(false);
  let total = 0;
  const createdAt = get(order, 'created_at');
  const date = new Date(createdAt);
  const status = getCurrentStep(order);

  return (
    <IonListWrapper key={get(order, 'key')} color={get(theme, 'color.productTitle')} bgColor={get(theme, 'color.background')} lines="none">
      <IonItemWrapper color={get(theme, 'color.background')}>
        <IonCol>
          <IonRow className="ion-justify-content-start">
            <IonText color={get(theme, 'color.title')}>
              N°&nbsp;
              <Bold data-cy={`order-key-${i}`}>{get(order, 'key')}</Bold>
            </IonText>
          </IonRow>
        </IonCol>
        <IonCol>
          <IonRow className="ion-justify-content-start">
            {get(order, 'parameters.preorderSlots') ? (
              <IonText color={get(theme, 'color.title')}>
                Prete pour le <br />
                <Bold>{`${formatNumber(date.getDate())}/${formatNumber(date.getMonth() + 1)}/${date.getFullYear()}`}</Bold> <br />
                entre <br />
                {'  '}
                <Bold>{get(order, 'parameters.preorderSlots')}</Bold>
              </IonText>
            ) : (
              <IonText color={get(theme, 'color.title')}>
                <Bold>{`${formatNumber(date.getDate())}/${formatNumber(date.getMonth() + 1)}/${date.getFullYear()}`}</Bold>
                &nbsp; à &nbsp;
                <Bold>{`${formatNumber(date.getHours())}:${formatNumber(date.getMinutes())}`}</Bold>
              </IonText>
            )}
          </IonRow>
        </IonCol>
      </IonItemWrapper>
      {map(get(order, 'items'), ({ price, id, quantity, reference, number }) => {
        quantity = quantity || number;
        total += price * quantity;

        return (
          <IonItemWrapper color={get(theme, 'color.background')} key={id} lines="none">
            <IonCol>
              <IonText color={get(theme, 'color.productTitle')}>{reference}</IonText>
              <div>
                <IonTextPrice color={get(theme, 'color.productPrice')}>
                  {`${Dinero({ amount: parseInt(price, 10) }).toFormat('0.00')} €`}
                </IonTextPrice>
              </div>
            </IonCol>
            <IonCol>
              <IonRow className="ion-justify-content-end">
                <IonText color={get(theme, 'color.productTitle')}>x{quantity}</IonText>
              </IonRow>
            </IonCol>
          </IonItemWrapper>
        );
      })}
      <IonItemWrapper color={get(theme, 'color.background')}>
        <IonCol>
          <IonText style={{ fontWeight: 'bold' }} color={get(theme, 'color.productTitle')}>
            Total
          </IonText>
        </IonCol>
        <IonCol>
          <IonRow className="ion-justify-content-end">
            <IonText style={{ fontWeight: 'bold' }} color={get(theme, 'color.productTitle')}>
              {' '}
              {`${Dinero({ amount: total }).toFormat('0.00')} €`}
            </IonText>
          </IonRow>
        </IonCol>
      </IonItemWrapper>
      {status === S1 || status === S2 || status === S3 || status === S4 ? (
        <div
          style={{
            display: 'flex',
            width: '100%',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <IonButton data-cy={`get-order-${i}`} color={get(theme, 'color.title')} onClick={() => followOrder(order)} size="small">
            Suivre ma commande
          </IonButton>
        </div>
      ) : (
        <div
          style={{
            display: 'flex',
            width: '100%',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <IonButton data-cy={`get-order-${i}`} color={get(theme, 'color.title')} onClick={() => setOpen(true)} size="small">
            Voir mon recu
          </IonButton>
        </div>
      )}
      <ModalResumeOrder receipe darkTheme={false} open={open} handleClose={() => setOpen(false)} orderPreparing={order} />
    </IonListWrapper>
  );
};
