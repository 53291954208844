/* eslint-disable max-len */
import * as firebase from 'firebase/app';
// import { notificationPermission } from '../notifications'

const windowT:any = window;

export const firebaseConfig = {
  apiKey: windowT.kardsConfiguration.apiKey,
  authDomain: windowT.kardsConfiguration.authDomain,
  databaseURL: windowT.kardsConfiguration.databaseUrl,
  projectId: windowT.kardsConfiguration.projectId,
  storageBucket: windowT.kardsConfiguration.storageBucket,
  messagingSenderId: windowT.kardsConfiguration.messagingSendersId,
  appId: windowT.kardsConfiguration.appId,
  measurementId: windowT.kardsConfiguration.measurementId,
};

firebase.initializeApp(firebaseConfig);

// to uncomment for active notification
// if (firebase.messaging.isSupported()) {
//   const messaging = firebase.messaging();
//   messaging.usePublicVapidKey("BAR9X1sSRpYS58yvXQO-ka8mEp8WNfm1DV72bP3RMrSdZbZ4g0XNQ1gEEfxSfiUkwa4cVUataYVfY4S8W8HOxoM");
//   notificationPermission(messaging)
//   if ('serviceWorker' in navigator) {
//     window.addEventListener('load', async () => {
//       const registration = await navigator.serviceWorker.register('/firebase-messaging-sw.js', {
//         updateViaCache: 'none'
//       });
//       messaging.useServiceWorker(registration);
//       messaging.onMessage((payload) => {
//         console.log('receive message', payload)
//       });
//     });
//   }
// }
