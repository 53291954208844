import React from 'react';
import { IonInput } from '@ionic/react';
import { Field } from 'formik';
import { useTheme } from 'src/modules/card/context/theme';
import { Text } from '../Text';

export function FirstNameField({ name = 'userInformations.firstname' }) {
  const theme = useTheme();
  return (
    <Field name={name}>
      {({ form, field }) => (
        <>
          <Text
            fontWeigth="bold"
            width="100%"
            justifyContent="flex-start"
            color={theme?.color?.title}
            paddingBottom=".2em"
            paddingTop="2em"
          >
            Prénom
          </Text>
          <IonInput
            data-cy="firstname_input"
            type="text"
            maxlength={15}
            placeholder="Mon prénom"
            value={field.value}
            onIonChange={e => form.setFieldValue('userInformations.firstname', e.detail.value)} />
        </>
      )}
    </Field>
  );
}
