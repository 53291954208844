import React from 'react';
import SimpleList from '@bit/kards.kards-components.simple-list';
import { useTheme } from 'src/modules/card/context/theme';
import { AddRemoveBasket } from 'src/modules/basket/components/AddRemoveBasket';

export default ({ data }) => {
  const theme = useTheme();

  return (
    <SimpleList.component theme={theme} key={data.id} data={data}>
      <AddRemoveBasket />
    </SimpleList.component>
  );
};
