/* eslint-disable camelcase */

async function chrome76Detection() {
  if ('storage' in navigator && 'estimate' in navigator.storage) {
    const { quota } = await navigator.storage.estimate();
    if (quota < 120000000) return true;
    return false;
  }
  return false;
}

function isNewChrome() {
  const pieces = navigator.userAgent.match(/Chrom(?:e|ium)\/([0-9]+)\.([0-9]+)\.([0-9]+)\.([0-9]+)/);
  if (pieces == null || pieces.length !== 5) {
    return undefined;
  }
  const major = pieces.map((piece) => parseInt(piece, 10))[1];
  if (major >= 76) { return true; }
  return false;
}

const PrivateWindow = new Promise(((resolve) => {
  try {
    const isSafari = navigator.vendor && navigator.vendor.indexOf('Apple') > -1
      && navigator.userAgent
      && navigator.userAgent.indexOf('CriOS') === -1
      && navigator.userAgent.indexOf('FxiOS') === -1;

    if (isSafari) {
      // Safari
      let e = false;
      if (window.safariIncognito) {
        e = true;
      } else {
        try {
          window.openDatabase(null, null, null, null);
          window.localStorage.setItem('test', 1);
          resolve(false);
        } catch (t) {
          e = true;
          resolve(true);
        }
        if (!e) window.localStorage.removeItem('test');
      }
    } else if (navigator.userAgent.includes('Firefox')) {
      // Firefox
      const db = indexedDB.open('test');
      db.onerror = function () { resolve(true); };
      db.onsuccess = function () { resolve(false); };
    } else if (navigator.userAgent.includes('Edge') || navigator.userAgent.includes('Trident') || navigator.userAgent.includes('msie')) {
      // Edge or IE
      if (!window.indexedDB && (window.PointerEvent || window.MSPointerEvent)) resolve(true);
      resolve(false);
    } else { // Normally ORP or Chrome
      // Other
      if (isNewChrome()) resolve(chrome76Detection());

      const fs = window.RequestFileSystem || window.webkitRequestFileSystem;
      if (!fs) resolve(null);
      else {
        fs(window.TEMPORARY, 100, () => {
          resolve(false);
        }, () => {
          resolve(true);
        });
      }
    }
  } catch (err) {
    console.log(err);
    resolve(null);
  }
}));

export function isPrivateWindow(callback) {
  PrivateWindow.then((is_private) => {
    callback(is_private);
  });
}
