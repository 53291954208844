import React, { useEffect } from 'react';
import { findIndex, get, map } from 'lodash';
import {
  IonRadioGroup, IonRadio, IonCheckbox, IonTextarea, IonItem, IonLabel,
} from '@ionic/react';
import styled from 'styled-components';
import { colors } from '@bit/kards.kards-components.helpers';
import { useReadContext } from '../card/context';
import { useTheme } from '../card/context/theme';

export const GetFieldByType = ({
  type, options, optionsNumber, onChange, value, error,
}) => {
  const { noOrder }: any = useReadContext();
  switch (type) {
    case 'input':
      return <Input disable={noOrder} onChange={onChange} value={value} error={error} />;
    case 'select':
      return optionsNumber > 1 ? (
        <Select disable={noOrder} error={error} onChange={onChange} value={value} options={options} />
      ) : (
        <Radio disable={noOrder} data-cy="" error={error} onChange={onChange} value={value} options={options} />
      );
    default:
      return <div />;
  }
};

const Input = ({
  onChange, value, error, disable,
}) => {
  const theme = useTheme();

  if (disable) return <div />;
  return (
    <>
      <IonTextarea
        data-cy="input"
        placeholder="Entrez votre text"
        value={value}
        onIonChange={(e: any) => e.detail.value && onChange(e.detail.value!)}
        color={get(theme, 'color.title')}
        disabled={disable}
      />
      {error && !disable && <div style={{ color: 'red' }}>{error}</div>}
    </>
  );
};

const Radio = ({
  options, onChange, value, error, disable,
}) => {
  const theme = useTheme();

  useEffect(() => {
    if (!value) {
      onChange(options[0].value);
    }
  }, []);

  return (
    <div data-cy="radio-input" style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
      <IonRadioGroup value={value} onIonChange={(e) => onChange(e.detail.value)}>
        {map(options, ({ value }) => (
          <IonItem key={value} lines="none" color={get(theme, 'color.background')}>
            <IonLabel style={{ whiteSpace: 'break-spaces', flex: '.8' }}>{value}</IonLabel>
            {!disable && <IonRadio slot="end" value={value} color={get(theme, 'color.productTitle')} mode="md" disabled={disable} />}
          </IonItem>
        ))}
      </IonRadioGroup>
      {error && !disable && <div style={{ color: 'red' }}>{error}</div>}
    </div>
  );
};

const Select = ({
  options, onChange, value, error, disable,
}) => {
  const theme = useTheme();

  const handleChange = (val, checked) => {
    if (!value) value = [];
    if (checked === true && !value.includes(val)) value.push(val);
    if (checked === false) {
      const index = findIndex(value, (e: any) => e === val);
      value.splice(index, 1);
    }
    onChange(value);
  };

  return (
    <div data-cy="select-input" style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
      {map(options, (d) => (
        <IonItem key={d.value} lines="none" color={get(theme, 'color.background')}>
          <IonLabel style={{ flex: '.8' }}>{d.value}</IonLabel>
          {!disable && (
            <Checkbox
              slot="end"
              mode="md"
              disable={disable}
              value={d.value}
              color={get(theme, 'color.productTitle')}
              borderColor={get(theme, 'color.title')}
              background={get(theme, 'color.background')}
              checked={value && value.includes(d.value)}
              onIonChange={(e) => handleChange(d.value, e.detail.checked)}
            />
          )}
        </IonItem>
      ))}
      {error && !disable && <div style={{ color: 'red' }}>{error}</div>}
    </div>
  );
};

const Checkbox = styled(IonCheckbox)`
  --background: ${(props) => colors[props.background]};
  --border-color: ${(props) => colors[props.borderColor]};
`;
