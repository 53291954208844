import React, { useState } from 'react';
import { attachIntents } from 'src/data/accessor/stripe';
import { CheckoutForm } from './CheckoutForm';
import { Error } from 'src/common-ui/Error'
export default ({
  setCreditCards, creditCards, setStep
}) => {
  const [loadingSubmit, setLoadingSubmit] = useState(false);
  const [error, setError] = useState();

  async function submit({ stripe, cardNumberElement, cardName }) {
    try {
      if (!stripe) {
        return;
      }
      setLoadingSubmit(true);

      // create intent
      const { error, paymentMethod } = await stripe.createPaymentMethod(
        {
          type: 'card',
          card: cardNumberElement,
          metadata: {
            name: cardName,
          },
        },
      );
      if (error) {
        throw error;
      }
      const attachIntentsData: any = await attachIntents({  payment_method: paymentMethod.id, });
      const dataAttachIntent = attachIntentsData.data;
      if (attachIntentsData.error) {
        throw attachIntentsData.error;
      }

      let card = {
        ...paymentMethod.card,
        payment_method_id:  dataAttachIntent.id,
        name: dataAttachIntent.metadata.name
      }
      setCreditCards([...creditCards, card]);
      setLoadingSubmit(false);
      setStep('paid')
    } catch (e) {
      setLoadingSubmit(false);
      setError(e);
    }
  }

  if(error) return <Error resetError={() => setError(null)} error={error} />
  return (
    <CheckoutForm
      onSubmit={submit}
      loading={loadingSubmit}
      type="submit"
      title={error ? 'Erreur : Réssayer' : 'Valider'}
    />
  );
};
