import { useState, useEffect } from 'react';
import firebase from 'firebase';

export const useLastOrder = ({ id }) => {
  const [data, setOrder] = useState();

  const getlastOrder = async id => {
    const defaultFirestore = firebase.app();

    let lastOrder: any = await defaultFirestore
      .firestore()
      .collection('orders')
      .doc(id)
      .get();

    setOrder(lastOrder.data());
  };

  useEffect(() => {
    getlastOrder(id);
  }, [id]);

  return data;
};
