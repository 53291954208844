import React from 'react';
import Carousel from '@bit/kards.kards-components.carousel';
import useMedia from 'use-media';
import { useTheme } from 'src/modules/card/context/theme';
import { AddRemoveBasket } from 'src/modules/basket/components/AddRemoveBasket';

export default ({ data: { id, products, title, description } }) => {
  const theme = useTheme();
  const isWide = useMedia({ minWidth: '500px' });

  return (
    <Carousel.component key={id} read theme={theme} data={{ products, title, description }} width={isWide ? '20vw' : '70vw'}>
      <AddRemoveBasket />
    </Carousel.component>
  );
};
