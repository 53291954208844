import { Categories, Component } from './types';

type DayOfTheWeek = 'sunday' | 'monday' | 'tuesday' | 'wednesday' | 'thursday' | 'friday' | 'saturday';

type Open = {
  [key in DayOfTheWeek]: { [key in 'midday' | 'night']: { start: string; end: string } };
};

const days = ['sunday', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday'];

function dayBefore(now = new Date()) {
  if (now.getDay() - 1 === -1) return 6;
  return now.getDay() - 1;
}

function needToUseLastDay(open: Open, now = new Date()) {
  if (!open) return false;
  const yesterdayOpenDay = open[days[dayBefore(now)]];
  const yesterdayNightSlotEnd = yesterdayOpenDay?.night?.end;
  const yesterdayNightSlotEndHours = yesterdayNightSlotEnd && parseInt(yesterdayNightSlotEnd.split(':')[0], 10);

  const currentHours = now.getHours();

  if (currentHours <= 4 && currentHours >= 0) {
    if (yesterdayNightSlotEndHours <= 4) {
      return true;
    }
  }
  return false;
}

export function getCurrentDay(open: Open, now = new Date()) {
  // keep yesterday if hour is more than 0 and less than 4
  if (needToUseLastDay(open, now)) return days[dayBefore(now)];
  return days[now.getDay()];
}

export function getCurrentService(open: Open, now = new Date()): 'midday' | 'night' {
  if (!open) return 'midday';
  if (needToUseLastDay(open, now)) return 'night';

  const day = open[days[now.getDay()] as DayOfTheWeek];
  const night = day.night;
  const midday = day.midday;

  function getServicesDate(service: { start: string; end: string }) {
    const startHours = parseInt(service.start.split(':')[0], 10);
    const startMin = parseInt(service.end.split(':')[1], 10);

    const endHours = parseInt(service.end.split(':')[0], 10);
    const endMin = parseInt(service.start.split(':')[1], 10);

    const dateStart = new Date(now);
    dateStart.setHours(startHours);
    dateStart.setMinutes(startMin);

    const dateEnd = new Date(now);
    dateEnd.setHours(endHours);
    dateEnd.setMinutes(endMin);

    return [dateStart, dateEnd] as const;
  }

  const [middayStart, middayEnd] = getServicesDate(midday);
  const [nightStart, nightEnd] = getServicesDate(night);

  if (now >= middayStart && now <= middayEnd) return 'midday';
  if (nightStart > nightEnd) return 'night';
  if (now >= nightStart && now <= nightEnd) return 'night';
  return 'midday';
}

type getComponentAvailableProps = {
  categories: Categories[];
  mode: any;
  day: any;
  service: any;
  open: Open;
};

export function getComponentAvailable({ categories, mode, day, service, open }: getComponentAvailableProps): Categories[] {
  const getDay = mode === 'instant' ? getCurrentDay(open) : day.value;
  const getService = mode === 'instant' ? getCurrentService(open) : service.value;

  function getSimpleListAndCarousel(component: Component) {
    return {
      ...component,
      value: {
        ...component?.value,
        products: component?.value?.products?.filter(product => product?.[mode]?.[getDay]?.[getService]),
      },
    };
  }

  function getProduct(component: Component) {
    return component?.value?.[mode]?.[getDay]?.[getService] ? component : undefined;
  }

  function getOffer(component: Component) {
    if (!getProduct(component)) return undefined;

    return {
      ...component,
      value: {
        ...component.value,
        settingFields: component?.value?.settingFields?.map(setting => ({
          ...setting,
          products: setting?.products?.filter(product => product?.[mode]?.[getDay]?.[getService]),
        })),
      },
    };
  }

  const filterCategories = categories?.map(category => {
    return {
      ...category,
      components: category.components
        .map(component => {
          switch (component.type) {
            case 'simpleList':
              return getSimpleListAndCarousel(component);
            case 'carousel':
              return getSimpleListAndCarousel(component);
            case 'product':
              return getProduct(component);
            case 'offer':
              return getOffer(component);
            default:
              return component;
          }
        })
        .filter(c => Boolean(c)),
    };
  });

  return filterCategories;
}
