import React from 'react';
import { Switch, Route } from 'react-router-dom';
import { DivideBill } from './1-DivideBill';
import { ShareLink } from './2-ShareLink';
import { DivideBillPaymentChoice } from './3-PaymentChoice';
import { DivideBillPayment } from './4-DivideBillPayment';
import { DivideBillPaymentStatus } from './5-DivideBillPaymentStatus';
import { GuardOrder } from '../components/GuardOrder';

export function DivideRoutes() {
  return (
    <Switch>
      <Route path="/bill/divide/" exact>
        <DivideBill />
      </Route>
      <Route path="/bill/divide/share-link/:id">
        <GuardOrder>
          <ShareLink />
        </GuardOrder>
      </Route>
      <Route path="/bill/divide/payment-choose/:id">
        <GuardOrder>
          <DivideBillPaymentChoice />
        </GuardOrder>
      </Route>
      <Route path="/bill/divide/payment/:id">
        <GuardOrder>
          <DivideBillPayment />
        </GuardOrder>
      </Route>
      <Route path="/bill/divide/payment-status/:id">
        <GuardOrder>
          <DivideBillPaymentStatus />
        </GuardOrder>
      </Route>
    </Switch>
  );
}
