import React, { useEffect, useRef } from 'react';
import { get } from 'lodash';
import lottie from 'lottie-web';
import styled from 'styled-components';
import { colors } from '@bit/kards.kards-components.helpers';
import creditCard from 'src/animations/creditCard.json';
import { useTheme } from 'src/modules/card/context/theme';

const LoadingContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  width: 100%;
  z-index: 35;
  height: 100vh;
  top: 0;
  bottom: 0;
  flex-direction: column;
  background-color: ${(props) => props.bgColor};
`;

const Text = styled.p`
  text-align: center;
  padding: .5em;
  font-size: ${(props) => props.fontSize};
  color: ${(props) => props.color};
  font-weight: ${(props) => props.fontWeight};
`;

type LoadingProps = {
  text?: string
}

export function Loading({ text = 'Chargement en cours' }:LoadingProps) {
  const theme = useTheme();
  const lottiContainerRef = useRef();

  useEffect(() => {
    const defaultOptions: any = {
      container: lottiContainerRef.current, // the dom element that will contain the animation
      renderer: 'svg',
      loop: true,
      autoplay: true,
      name: 'paiement',
      animationData: creditCard,
    };
    lottie.loadAnimation(defaultOptions);

    return () => lottie.destroy('paiement');
  }, []);

  return (
    <LoadingContainer bgColor={colors[get(theme, 'color.background')]}>
      <div style={{ width: '30vh', height: '30vh' }} ref={lottiContainerRef} />
      <Text color={colors[get(theme, 'color.productTitle')]} fontWeight={400} fontSize="1.3em">
        {text}
      </Text>
    </LoadingContainer>
  );
}

export default Loading;
