const colors = [
    'rgb(209, 250, 255)',
    'rgb(155, 209, 229)',
    'rgb(106, 142, 174)',
    'rgb(87, 167, 115)',
    'rgb(21, 113, 69)'
]

export function getRandColors() {
    return colors[Math.ceil(Math.random()*colors.length-1)]
}