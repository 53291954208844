import React, { Fragment } from 'react';
import { get, map } from 'lodash';
import { IonLabel, IonItem, IonIcon, IonText, IonRadioGroup, IonRadio } from '@ionic/react';
import styled from 'styled-components';

import { colors } from '@bit/kards.kards-components.helpers';
import { helpCircleOutline } from 'ionicons/icons';

const Label = styled(IonLabel)`
  align-items: center;
  color: ${props => colors[props.color]};
  display: flex !important;
  flex-direction: row;
`;

const Radio = styled(IonRadio)`
  margin: 0.5em !important;
`;

const IonItemWrapperTips = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  background: ${props => colors[props.color]} !important;
`;

const Item = styled(IonItem)`
  background: ${props => colors[props.color]} !important;
  --background: ${props => colors[props.color]} !important;
`;

const IonTextExplain = styled(IonText)`
  margin: 0;
  padding: 0 7px;
  font-size: 0.8em;
`;

export const ItemRadioGroupDelivery = ({
  allowEmptySelection,
  items,
  theme,
  onChange,
  name,
  current,
  deliveryInstruction,
  deliveryInstructionAdresse,
}) => (
  <IonRadioGroup style={{ width: '100%' }} allowEmptySelection={allowEmptySelection} onIonChange={onChange} name={name} value={current}>
    {map(items, ({ label, value, icon }, i) => (
      <Fragment key={value}>
        <Item lines="none" key={i} color={get(theme, 'color.background')}>
          <Label color={get(theme, 'color.title')}>
            {icon && <IonIcon style={{ marginRight: '10px' }} size="large" src={icon} />}
            <IonText color={get(theme, 'color.title')}>{label}</IonText>
          </Label>
          <IonRadio mode="md" value={value} slot="end" color={get(theme, 'color.title')} data-cy={`${value}-mode`} />
        </Item>
        {value === 'delivered' && deliveryInstruction && (
          <div
            style={{
              display: 'flex',
              justifyContent: 'flex-start',
              alignItems: 'center',
              paddingLeft: '1em',
            }}
          >
            <IonIcon slot="icon-only" icon={helpCircleOutline} />
            <IonTextExplain color={get(theme, 'color.title')}>
              {deliveryInstructionAdresse ? (
                <a style={{ color: colors[get(theme, 'color.title')] }} target="blank" href={deliveryInstructionAdresse}>
                  {deliveryInstruction}
                </a>
              ) : (
                deliveryInstruction
              )}
            </IonTextExplain>
          </div>
        )}
      </Fragment>
    ))}
  </IonRadioGroup>
);

export const ItemRadioGroup = ({ allowEmptySelection, items, theme, onChange, name, current }) => (
  <IonRadioGroup style={{ width: '100%' }} allowEmptySelection={allowEmptySelection} onIonChange={onChange} name={name} value={current}>
    {map(items, ({ label, value, icon, width = '50px ' }) => (
      <Item lines="none" key={value} color={get(theme, 'color.background')}>
        <Label color={get(theme, 'color.title')}>
          {icon && (
            <div style={{ width: '80px' }}>
              <img src={icon} style={{ width }} />
            </div>
          )}
          <IonText color={get(theme, 'color.title')}>{label}</IonText>
        </Label>
        <IonRadio mode="md" value={value} slot="end" color={get(theme, 'color.title')} data-cy={`${value}-mode`} />
      </Item>
    ))}
  </IonRadioGroup>
);

export const ItemRadioGroupHorizontalTips = ({ allowEmptySelection, items, theme, onChange, name, current, style }) => (
  <IonRadioGroup
    onIonChange={onChange}
    allowEmptySelection={allowEmptySelection}
    color={get(theme, 'color.background')}
    name={name}
    style={{
      display: 'flex',
      justifyContent: 'space-between',
      width: '80%',
      height: '10vh',
    }}
  >
    {map(items, ({ label, value, icon }) => (
      <IonItemWrapperTips data-cy={`${value}-key`} key={value} color={get(theme, 'color.background')}>
        <Label color={get(theme, 'color.title')}>
          {icon && <IonIcon style={{ marginRight: '10px' }} size="large" src={icon} />}
          <IonText color={get(theme, 'color.title')}>{label}</IonText>
        </Label>
        <Radio
          style={{
            height: '5vh',
            width: '5vh',
            ...style,
          }}
          mode="md"
          checked={value === current}
          value={value}
          slot="end"
          color={get(theme, 'color.title')}
        />
      </IonItemWrapperTips>
    ))}
  </IonRadioGroup>
);

export const ItemRadioGroupHorizontal = ({ allowEmptySelection, items, theme, onChange, name, current }) => (
  <IonRadioGroup
    onIonChange={onChange}
    allowEmptySelection={allowEmptySelection}
    color={get(theme, 'color.background')}
    name={name}
    style={{ display: 'flex', justifyContent: 'space-between' }}
  >
    {map(items, ({ label, value, icon }) => (
      <Item key={value} color={get(theme, 'color.background')}>
        <Label color={get(theme, 'color.title')}>
          {icon && <IonIcon style={{ marginRight: '10px' }} size="large" src={icon} />}
          <IonText color={get(theme, 'color.title')}>{label}</IonText>
        </Label>
        <Radio mode="md" checked={value === current} value={value} slot="end" color={get(theme, 'color.title')} />
      </Item>
    ))}
  </IonRadioGroup>
);
