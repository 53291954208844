import React from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { ShareLinkComponent } from '../components/ShareLink';

export function ShareLink() {
  const history = useHistory();
  const { id: orderId } = useParams();

  const shareURL = `${window.location.origin}/bill/divide/payment-choose/${orderId}`;

  const submit = () => {
    history.push({ pathname: `/bill/divide/payment-choose/${orderId}`, state: { paidRestOfBill: true } });
  };

  return (
    <ShareLinkComponent
      title="Partager le lien"
      info="Maintenant, cliquez sur demander pour envoyer un lien de paiement à chaque participant !"
      shareURL={shareURL}
      onSubmit={submit}
    />
  );
}
