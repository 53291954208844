import React, { createContext, useState, useEffect, useContext } from 'react';
import localforage from 'localforage';
import { get } from 'lodash';
import { createPaymentIntentsFromExisting } from 'src/data/accessor/stripe';
import { log } from 'src/helpers/logger';
import { removeOrder as removeOrderInFirebase } from 'src/data/accessor/removeOrder';
import { useHistory } from 'react-router-dom';

type ContextType = {
  removeOrder: () => Promise<void>;
  tipsSuccess: { error?: boolean; success?: boolean };
  orderPreparing: { loading: boolean; data: any };
  darkTheme: boolean;
  completOrderPreparing: () => Promise<void>;
  setOrderPreparing: React.Dispatch<React.SetStateAction<{ loading?: boolean; data?: any }>>;
  payTips: ({ tips }: { tips: any }) => Promise<void>;
};

export const OrderPreparingContext = createContext<ContextType>({} as ContextType);

export function useOrderPreparingContext() {
  return useContext(OrderPreparingContext);
}

export function OrderPreparingProvider({ children }) {
  const [orderPreparing, setOrderPreparing] = useState({ loading: false, data: null });
  const [darkTheme, setDarkTheme] = useState(false);
  const [tipsSuccess, setTipsSuccess] = useState<{ error?: boolean; success?: boolean }>({
    error: false,
    success: false,
  });
  const history = useHistory();
  const LIMITE_LIGHT_MOD = 20;

  useEffect(() => {
    const hours = new Date().getHours();
    if (hours >= LIMITE_LIGHT_MOD) setDarkTheme(true);
  }, []);

  const completOrderPreparing = async () => {
    const items = get(orderPreparing, 'data');
    await localforage.setItem('lastOrder', items);
    await localforage.removeItem('basketProducts');
    await localforage.removeItem('orderPreparing');
    setOrderPreparing({ loading: true, data: null });
    setTipsSuccess({
      error: false,
      success: false,
    });
    history.push('/');
  };

  const payTips = async ({ tips }) => {
    let time = 1500;
    if (tips) {
      setTipsSuccess({ success: true });
      const tipsFn: any = await createPaymentIntentsFromExisting({
        amount: tips,
        orderId: orderPreparing.data,
      });

      if (tipsFn.error) {
        log('createPaymentIntentsFromExisting_error', {
          error: tipsFn.error,
        });
        setTipsSuccess({ error: true });
        time = 3000;
      }
      setTimeout(() => {
        completOrderPreparing();
      }, time);
    }
  };

  const removeOrder = async () => {
    localforage.removeItem('orderPreparing');
    await removeOrderInFirebase({ order: orderPreparing.data });
    setOrderPreparing({ loading: true, data: null });
  };

  const followOrder = currentOrder => {
    localforage.setItem('orderPreparing', currentOrder.id);
    setOrderPreparing({ loading: false, data: currentOrder.id });
  };

  useEffect(() => {
    localforage.getItem('orderPreparing').then(value => {
      if (value) {
        setOrderPreparing({ loading: true, data: value });
      } else {
        setOrderPreparing({ loading: true, data: null });
      }
    });
  }, []);

  const value = {
    removeOrder,
    tipsSuccess,
    orderPreparing,
    darkTheme,
    completOrderPreparing,
    setOrderPreparing,
    payTips,
    followOrder,
  };

  return <OrderPreparingContext.Provider value={value}>{children}</OrderPreparingContext.Provider>;
}
