import React, { useEffect } from 'react';
import { map, get } from 'lodash';
import { useHistory } from 'react-router';
import { templateType } from '@bit/kards.kards-components.helpers';
import styled from 'styled-components';
import { Footer } from 'src/common-ui';
import { useReadContext } from './context';
import { getTemplate } from './kardTemplate/getTemplate';
import { useBasketContext } from 'src/modules/basket';
import { useLastOrder } from 'src/data/hooks/useLastOrder';

const ButtonSame = styled.div`
  position: absolute;
  z-index: 1000;
  bottom: 10vh;
  right: 10px;
  height: 9vh;
  width: 9vh;
  box-shadow: 0px 0px 30px -11px #a5a5a5;
  border-radius: 60px;
  overflow: visible;
  background: transparent;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const ButtonSameWithData = ({ updateBaskets, id, history }) => {
  let data: any = useLastOrder({ id });

  if (!data) return <div></div>;

  return (
    data && (
      <ButtonSame
        onClick={() => {
          history.push('/orders/confirm');
          map(data?.items, d => updateBaskets(d.id, () => d));
        }}
      >
        <img data-cy="same-order" src="/img/utils/same.svg" style={{ height: '9vh', width: '9vh' }} alt="" />
      </ButtonSame>
    )
  );
};

function CardRead() {
  const { lastOrder, setCurrentCategory, currentCategory, cardData, cardTemplate, templateName }: any = useReadContext();

  const { nbProducts, updateBaskets } = useBasketContext();
  const history = useHistory();

  useEffect(() => {
    if (
      !currentCategory &&
      (get(cardTemplate, 'name') === templateType.Top || (get(cardTemplate, 'name') === templateType.TopWithText && !currentCategory))
    ) {
      setCurrentCategory(get(cardData, '0.id'));
    }
  }, [cardData]);

  return (
    <>
      {getTemplate(templateName)}
      {lastOrder && <ButtonSameWithData history={history} updateBaskets={updateBaskets} id={lastOrder} />}
      <Footer
        title={'Commander'}
        disabled={nbProducts <= 0}
        data-cy="btn-order-card"
        displayNumber
        displayPrice
        onClick={() => history.push('/orders/confirm')}
      />
    </>
  );
}

export default React.memo(CardRead);
