import React from 'react';
import { get } from 'lodash';
import { useTheme } from 'src/modules/card/context/theme';
import { Text } from './Text';

export function PreOrderTitle({ label, children }) {
  const theme = useTheme();
  return (
    <>
      <Text fontWeigth="300" width="50%" textAlign="center" color={theme?.color?.title} justifyContent="center" whiteSpace="nowrap">
        {label}
      </Text>
      <Text
        margin=".5em"
        padding=".5em 1em"
        borderRadius="30px"
        bgColor={get(theme, 'color.title')}
        color={get(theme, 'color.background')}
        fontWeigth="bold"
        justifyContent="center"
        alignItems="center"
      >
        {children}
      </Text>
    </>
  );
}
