import React from 'react';
import { get, map } from 'lodash';
import { IonItemGroup, IonItemDivider, IonItem, IonLabel } from '@ionic/react';
import { Field, Form as FormikForm } from 'formik';
import { colors } from '@bit/kards.kards-components.helpers';
import { useTheme } from 'src/modules/card/context/theme';
import { AddRemoveProductUI } from 'src/modules/basket';
import { Footer } from 'src/common-ui';
import { GetFieldByType } from './Field';
import { useReadContext } from 'src/modules/card/context';
import { useBasketContext } from 'src/modules/basket';
import { getRandColors } from 'src/helpers/colors'

const colorsBackground = getRandColors()

export function Form({ displayQuantity = true, entity, errors, SettingsFieldComponent = null }) {
  const { noOrder }: any = useReadContext();
  const theme = useTheme();
  const { nbProducts, totalPrice } = useBasketContext();
  return (
    <FormikForm style={{ overflow: 'scroll', paddingBottom: '120px' }}>
      <div
        style={{
          position: 'relative',
          justifyContent: 'center',
          alignItems: 'center',
          display: 'flex',
          width: '100%',
          height: '40%',
        }}
      >
        {entity.picture ? (
           <div style={{ width: '100%', minHeight: "30vh"}} >
            <img alt="pruduct" src={entity.picture} style={{ width: '100%', height: '100%' }} />
          </div>
        ) : (
          <div style={{ width: '100%', height: '33vh', backgroundColor: colorsBackground }} />
        )}
        <p
          style={{
            position: 'absolute',
            padding: '10px',
            color: 'white',
            fontSize: '2em',
            backgroundColor: 'rgba(200,200,200,0.5)',
          }}
        >
          {entity.reference}
        </p>
      </div>
      <p
        style={{
          background: colors[get(theme, 'color.background')],
          padding: '10px',
          fontWeight: 'bold',
          margin: 0,
          color: get(theme, 'color.productTitle'),
        }}
      >
        {entity.description}
      </p>
      <hr style={{ margin: '0px' }} />
      <IonItemGroup data-cy="list-items-options">
        {map(entity.settingFields, settingField => (
          <SettingsFieldComponent settingField={settingField} />
        ))}
        <div style={{ background: colors[get(theme, 'color.background')] }}>{!noOrder && displayQuantity && <QuantityField />}</div>
      </IonItemGroup>
      <Footer type="submit" title="Valider" disabled={Object.keys(errors).length > 0} nbProducts={nbProducts} price={totalPrice} />
    </FormikForm>
  );
}

export function QuantityField() {
  const theme = useTheme();
  return (
    <Field name="quantity">
      {({ field, form }) => (
        <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column'}}>
          <IonItemDivider mode="md" color={get(theme, 'color.background')}>
            <IonLabel style={{ paddingLeft: '16px' }}> Combien en voulez vous ?</IonLabel>
          </IonItemDivider>
          <AddRemoveProductUI
            onClickLess={() => form.setFieldValue(field.name, field.value - 1)}
            onClickMore={() => form.setFieldValue(field.name, field.value + 1)}
            disabledLess={field.value === 0}
          >
            {field.value}{' '}
          </AddRemoveProductUI>
        </div>
      )}
    </Field>
  );
}

export function SettingField({ settingField }) {
  const theme = useTheme();
  return (
    <div style={{ background: colors[get(theme, 'color.background')], padding: '1em', width: '100%' }}>
      <IonItemDivider mode="md" color={get(theme, 'color.background')}>
        <IonLabel>{settingField.label}</IonLabel>
      </IonItemDivider>
      <Field name={`options.${settingField.label}`}>
        {({ field, form, meta }) => (
          <IonItem lines="none" color={get(theme, 'color.background')}>
            <GetFieldByType
              onChange={value => {
                form.setFieldValue(field.name, value);
              }}
              error={meta.error}
              value={field.value}
              type={settingField.type}
              options={settingField.options}
              optionsNumber={settingField.optionsNumber}
            />
          </IonItem>
        )}
      </Field>
    </div>
  );
}
