import React from 'react';
import Offer from '@bit/kards.kards-components.offer';
import { get } from 'lodash';
import { AddRemoveBasket } from 'src/modules/basket/components/AddRemoveBasket';

import { Link } from 'react-router-dom';
import { useTheme } from 'src/modules/card/context/theme';
import { useBasketContext } from 'src/modules/basket';

const LinkWithCheck = ({ data, to, ...props }) => {
  const { isOffertDisponible } = useBasketContext();
  return isOffertDisponible({ ...data, type: 'offer' }) ? <Link to={to} {...props} /> : <span {...props} />;
};

export default ({ data }) => {
  const theme = useTheme();
  const { isOffertDisponible } = useBasketContext();

  return (
    <LinkWithCheck
      key={get(data, 'id')}
      style={{ textDecoration: 'none' }}
      data={data}
      to={{
        pathname: '/offer',
        state: { offer: data },
      }}
    >
      <Offer.component
        theme={theme}
        name={get(data, 'reference')}
        stock={{ unlimited: isOffertDisponible(data) }}
        picture={get(data, 'picture')}
        id={get(data, 'id')}
        description={get(data, 'description')}
        settingFields={get(data, 'settingFields')}
        price={get(data, 'price')}
        tva={get(data, 'tva')}
        key={get(data, 'id')}
        width="100%"
        read
      >
        <AddRemoveBasket />
      </Offer.component>
    </LinkWithCheck>
  );
};
