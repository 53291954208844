import React, { useContext } from 'react';
import { get } from 'lodash';
import styled from 'styled-components';
import { colors } from '@bit/kards.kards-components.helpers';
import { useLocalForage } from 'src/data/hooks/getStorage';
import { ThemeContext } from 'src/modules/card/context/theme';
import { ReactComponent as Logo } from 'src/svg/logo.svg';

const BoxWrapper = styled.div`
  background: ${(props) => props.background};
  flex-direction: column;
  justify-content: center;
  align-items: center;
  display: flex;
  flex:1;
  :focus {
    outline-width: 0;
  }
  margin-bottom: 8vh;
`;

const LogoSvg = styled(Logo)`
  width: 8em;
  margin: .5em;
  fill: ${(props) => props.color} !important;
  .st0{
    fill: ${(props) => props.color} !important;
  }
`;


const Text = styled.div`
  text-align: ${(props) => props.textAlign};
  width: ${(props) => props.width};
  color: ${(props) => (props.color)};
  font-size: ${(props) => props.fontSize};
  font-weight: ${(props) => props.fontWeight};
  font-family: ${(props) => props.family};
  padding: ${(props) => props.padding};
  padding-bottom: ${(props) => props.paddingBottom};
  padding-top: ${(props) => props.paddingTop};
  white-space: ${(props) => props.whiteSpace};
  line-height: ${(props) => props.lineHeight};
  text-decoration: ${(props) => props.textDecoration};
  display: ${(props) => props.display};
  justify-content: center;
  align-items: center;
`;

const About = () => {
  const {
    theme,
  }: any = useContext(ThemeContext);
  const version = useLocalForage({ key: 'version' });

  return (
    <BoxWrapper
      background={colors[get(theme, 'color.background')]}
    >
      <img height={get(theme, 'header.logoSizeHeight')} src={get(theme, 'header.logo')} alt="header" />
      <strong style={{
        color: colors[get(theme, 'color.title')],
        padding: '10px',
      }}
      >
        By

      </strong>
      <LogoSvg color={colors[get(theme, 'color.title')]} />

      <Text color={colors[get(theme, 'color.title')]} fontSize="1em" textAlign="center" width="80%" fontWeight="300" paddingTop="1vw">
        Version
        {' '}
        {version}
      </Text>
    </BoxWrapper>
  );
};


export default About;
