import React from 'react';

import { ComponentsRead } from 'src/modules/card/kardComponents/items';
import CategoryList from 'src/modules/card/kardComponents/CategoryHeaderWithText';
import { IonHeader } from '@ionic/react';

export default
  <>
    <IonHeader>
      <CategoryList />
    </IonHeader>
    <ComponentsRead />
  </>;
