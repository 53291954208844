import React from 'react';
import ReactDOM from 'react-dom';
// import { register } from './serviceWorker';

// register();
const script = document.createElement('script');
script.src = `/kardsConfiguration/index.js?bustCache=${Date.now()}`;
document.head.appendChild(script);
script.onload = function () {
  const App = require('./App');
  ReactDOM.render(<App.default />, document.getElementById('root'));
};
