import React, { useState } from 'react';
import { CardNumberElement, CardExpiryElement, CardCvcElement, useElements, useStripe } from '@stripe/react-stripe-js';
import styled from 'styled-components';
import { IonRow, IonCol, IonInput, IonLabel } from '@ionic/react';
import { colors } from '@bit/kards.kards-components.helpers';
import { useTheme } from 'src/modules/card/context/theme';
import { Footer, Content } from 'src/common-ui';

const InputWrapper = styled.div`
  padding: 10px 20px 11px;
  border: 1px solid grey;
  color: grey;
  border-radius: 5px;
  width: ${props => props.width};
`;

const Row = styled.div`
  padding: 5em 2em;
  position: relative;
  background-color: ${props => props.color};
  height: 100%;
  flex-direction: column;
  transition-property: opacity, transform;
  transition-duration: 0.35s;
  transition-timing-function: cubic-bezier(0.165, 0.84, 0.44, 1);
`;

const Label = styled(IonLabel)`
  font-size: 1.1em;
`;

const InputStyled = styled(IonInput)`
  --color: ${props => props.colorStyle};
`;

const createOptions: any = ({ color, placeHolderColor }) => ({
  style: {
    base: {
      color,
      fontWeight: 600,
      fontSize: '16px',
      border: `1px solid ${color}`,
      fontSmoothing: 'antialiased',
      ':-webkit-autofill': {
        color: '#fce883',
      },
      ':focus': {
        color: 'black',
      },

      '::placeholder': {
        color: placeHolderColor,
      },

      ':focus::placeholder': {
        color: 'lightgrey',
      },
    },
    invalid: {
      color: '#FA755A',
      ':focus': {
        color: '#FA755A',
      },
      '::placeholder': {
        color: '#FA755A',
      },
    },
  },
});

export type CheckoutFormProps = {
  onSubmit: (props: { stripe: any; cardName: string; cardNumberElement: any; cardExpiryElement: any; cardCvcElement: any }) => void;
};

export const CheckoutForm = ({ onSubmit, ...props }: any) => {
  const theme = useTheme();
  const [cardName, setCardName] = useState('');
  const elements = useElements();
  const stripe = useStripe();

  const options = React.useMemo(
    () =>
      createOptions({
        color: colors[theme?.color?.title],
        placeHolderColor: colors[theme?.color?.subtitle],
      }),
    [theme],
  );

  const [cardValidation, setCardValidation] = useState<any>({});

  const isCardValid =
    cardValidation.cardNumber &&
    cardValidation.cardNumber.isValid &&
    cardValidation.cardExpiry &&
    cardValidation.cardExpiry.isValid &&
    cardValidation.cardCvc &&
    cardValidation.cardCvc.isValid;

  const handleChange = e => {
    setCardValidation(c => ({
      ...c,
      [e.elementType]: {
        isValid: !e.error,
        error: e.error ? e.error.message : '',
      },
    }));
  };

  const submit = (e: React.SyntheticEvent) => {
    e.preventDefault();
    const cardNumberElement = elements.getElement(CardNumberElement);
    const cardExpiryElement = elements.getElement(CardExpiryElement);
    const cardCvcElement = elements.getElement(CardCvcElement);
    onSubmit({
      stripe,
      cardName,
      cardNumberElement,
      cardExpiryElement,
      cardCvcElement,
    });
  };

  return (
    <Content fullscreen>
      <form onSubmit={submit} style={{ height: '100%', paddingBottom: '380px' }}>
        <Row color={colors[theme?.color?.background]}>
          <IonCol color={theme?.color?.background}>
            <Label color={theme?.color?.productTitle} position="stacked">
              Donnez un petit nom à votre carte
            </Label>
            <InputStyled
              data-cy="card-name"
              type="text"
              color={theme?.color?.title}
              maxlength={10}
              onIonChange={e => setCardName(e.detail.value)}
              placeholder="Ex : La carte de Michel"
            />
          </IonCol>
          <IonRow style={{ margin: '30px 0' }}>
            <InputWrapper width="100%">
              <CardNumberElement data-cy="number-element" options={options} onChange={handleChange} />
            </InputWrapper>
          </IonRow>
          <IonRow style={{ justifyContent: 'space-between', margin: '30px 0' }}>
            <InputWrapper width="45%">
              <CardExpiryElement data-cy="expiry-element" options={options} onChange={handleChange} />
            </InputWrapper>
            <InputWrapper width="45%">
              <CardCvcElement data-cy="cvc-element" options={options} onChange={handleChange} />
            </InputWrapper>
          </IonRow>
        </Row>
        {!props.loadingSubmit && (
          <Footer
            data-cy="btn-order-paid"
            title={props.title}
            type="submit"
            disabled={!isCardValid}
            displayNumber
            displayPrice
            {...props}
          />
        )}
      </form>
    </Content>
  );
};
