import React from 'react';
import { Field } from 'formik';
import { useTheme } from 'src/modules/card/context/theme';
import { SelectTakeAway } from './SelectTakeAway';
import { Text } from '../Text';

export function PreorderSlotsField({ name = 'preorderSlots', dataAverageTime }) {
  const theme = useTheme();

  return (
    <Field name={name}>
      {({ field }) => (
        <>
          <Text
            fontWeigth="bold"
            width="100%"
            justifyContent="flex-start"
            color={theme?.color?.title}
            paddingBottom=".2em"
            paddingTop="3em"
          >
            Commander pour quand ?
          </Text>
          <SelectTakeAway dataAverageTime={dataAverageTime} name={field.name} onChange={field.onChange} value={field.value} />
        </>
      )}
    </Field>
  );
}
